import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";
import { ThemeStateInterface } from "../interfaces/ThemeState.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";

export const themeStore: KoruStoreModuleInterface<ThemeStateInterface> = createStoreModule("themeState", initialState, getters, actions);

export const useThemeStore = (): KoruStoreModuleInterface<ThemeStateInterface> => {
  return themeStore;
};
