import firebase from "firebase/compat/app";
import "firebase/compat/storage";

import { firebaseStorage } from "@/core/modules/firebase";
import { StorageInterface } from "../interfaces/Storage.interface";

import { convertBlobToBase64, fetchAsBlob } from "../helpers";

export class StorageModel implements StorageInterface {
  public async getBase64(name: string): Promise<string> {
    try {
      const url: string = await firebaseStorage.ref().child(name).getDownloadURL();
      const blob = await fetchAsBlob(url);
      const doubleBase64EncodedFile: string = (await convertBlobToBase64(blob)) as string;
      const doubleEncodedBase64String = doubleBase64EncodedFile.split(",")[1];
      return doubleEncodedBase64String;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async upload(name: string, file: File, callback: (progress: number) => void): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uploadTask: firebase.storage.UploadTask = firebaseStorage.ref().child(name).put(file);

      uploadTask.on(
        "state_changed",
        (snapshot: firebase.storage.UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          callback(Math.floor(progress));
        },
        (error: firebase.storage.FirebaseStorageError) => {
          // Handle unsuccessful uploads
          console.log(error);
          reject();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl: string) => {
            console.log("File available at", downloadUrl);
            resolve(downloadUrl);
          });
        }
      );
    });
  }

  public async delete(name: string): Promise<void> {
    try {
      firebaseStorage.ref().child(name).delete();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
}
