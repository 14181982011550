import { LinkedUnit } from "@/features/modules/unit/objects/LinkedUnit";
import { Section } from "@/features/modules/section/objects/Section";
import { sectionModel } from "../Section.model";
import { SectionWithUnits } from "@/features/modules/section/objects/SectionWithUnits";
import { Unit } from "@/features/modules/unit/objects/Unit";
import { unitModel } from "@/features/modules/unit/models/Unit.model";

export const getSectionsWithUnits = async (): Promise<SectionWithUnits[]> => {
  try {
    const sectionsWithUnits: SectionWithUnits[] = [];

    const sections: Section[] = await sectionModel.getDocuments();
    const units: Unit[] = await unitModel.getDocuments();

    for (const section of sections) {
      const sectionWithUnits: SectionWithUnits = new SectionWithUnits();
      sectionWithUnits.id = section.id;
      sectionWithUnits.name = section.name;
      sectionWithUnits.units = units.filter((unit) => unit.section?.id === section.id).map((unit) => LinkedUnit.createFromUnit(unit));

      if (sectionWithUnits.units.length > 0) sectionsWithUnits.push(sectionWithUnits);
    }

    return sectionsWithUnits;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
