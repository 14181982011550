export const de = {
  section: {
    section: "Abschnitt",
    sections: "Abschnitte",
    name: "Name",
    nameError: "Geben Sie einen gültigen Namen ein",
  },
  navigation: {
    section: "Abschnitte",
  },
  roleModules: {
    section: "Abschnitte",
  },
};
