import { GetterTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { LocaleInterface } from "../interfaces/Locale.interface";
import { LocaleStateInterface } from "../interfaces/LocaleState.interface";
import { localeStoreTypes } from "./types";
import { User } from "@/core/modules/user/objects/User";

export const getters: GetterTree<LocaleStateInterface, KoruStateInterface> = {
  [localeStoreTypes.getters.getSelectedLocale]: (state: LocaleStateInterface): LocaleInterface => {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    let selectedLocale: LocaleInterface = state.locales[0];
    for (const locale of state.locales) {
      if (locale.name == user.detail.locale) {
        selectedLocale = locale;
        break;
      }
    }

    return selectedLocale;
  },
  [localeStoreTypes.getters.getLocales]: (state: LocaleStateInterface): LocaleInterface[] => {
    return state.locales;
  },
};
