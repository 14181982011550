import { ActionContext } from "vuex";

import { AuthStateInterface } from "@/core/modules/auth/interfaces/AuthState.interface";
import { authStoreTypes } from "../types";
import { koruAuth } from "@/core/modules/auth/module";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

export const forgotPassword = async (context: ActionContext<AuthStateInterface, KoruStateInterface>, email: string): Promise<void> => {
  context.commit(authStoreTypes.mutations.loadingStart);

  try {
    return koruAuth.forgotPassword(email);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  } finally {
    context.commit(authStoreTypes.mutations.loadingStop);
  }
};
