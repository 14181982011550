import { User } from "../objects/User";
import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { UserModelInterface } from "../interfaces/UserModel.interface";

import { getUsersIdsByModuleAndRight, isUserEmailUnique } from "./methods";

export class UserModel extends FirestoreModel<User> implements UserModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(User, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<User[]> {
    return super.getDocuments("fullName", "asc");
  }

  public async getDocument(userId: string): Promise<User> {
    return super.getDocument(userId);
  }

  public async createDocument(user: User, logAction = true): Promise<string> {
    user.setFullNames();
    return super.createDocument(user, logAction);
  }

  public async updateDocument(user: User, logAction = true): Promise<void> {
    user.setFullNames();
    return super.updateDocument(user, logAction);
  }

  public async deleteDocument(user: User, logAction = true): Promise<void> {
    return super.deleteDocument(user, logAction);
  }

  public async searchDocuments(searchText: string): Promise<User[]> {
    return super.searchDocuments(searchText, "fullName", "asc");
  }

  /* custom methods */

  public async getUsersIdsByModuleAndRight(module: string, right: string): Promise<string[]> {
    return getUsersIdsByModuleAndRight(module, right);
  }

  public async isUserEmailUnique(email: string, oldEmail: string | undefined): Promise<boolean> {
    return isUserEmailUnique(email, oldEmail);
  }
}

export const userModel: UserModel = new UserModel("users", undefined, true, "coreUserBeforeDelete");
