export const en = {
  member: {
    addresses: "Contact details",
    approvedNote: "Member approved on {timestamp}",
    birthDate: "Birth date",
    birthDateError: "Enter a valid birth date",
    country: "Country",
    email: "Primary e-mail address",
    emailError: "Enter a valid e-mail address",
    emailNote: "At least one e-mail address should be in the skyguide.ch domain",
    exportMembers: "Export members",
    firstName: "First name",
    firstNameError: "Enter a valid first name",
    fullName: "Full name",
    gender: "Gender",
    genderError: "Select a gender",
    genders: {
      female: "Female",
      male: "Male",
    },
    groups: "Groups",
    helveticaId: "HelvetiCA ID",
    info: "Information",
    lastName: "Last name",
    lastNameError: "Enter a valid last name",
    mainUnit: "Main unit",
    mainUnitError: "Select a main unit",
    member: "Member",
    members: "Members",
    miscData: "Additional data",
    notificationMode: "Notification mode",
    notificationModes: {
      none: "None",
      push: "App push notification",
      primaryEmail: "Primary e-mail",
      secondaryEmail: "Secondary e-mail",
    },
    notes: "Notes",
    picture: "Picture",
    phone: "Phone",
    phoneError: "Enter a valid phone number",
    province: "State",
    secondaryEmail: "Secondary e-mail address",
    secondaryEmailError: "Enter a valid e-mail address",
    section: "Section",
    skyguideEmployeeId: "Skyguide employee ID",
    skyguideEmployeeIdError: "Enter a valid Skyguide employee ID",
    skyguideEntryDate: "Skyguide entry date",
    skyguideEntryDateError: "Enter a valid entry date",
    solidarityConsent: "Solidarity consent",
    spokenLanguages: "Spoken languages",
    status: "Status",
    statusError: "Select a status",
    street: "Address",
    town: "Town",
    units: "Units",
    workData: "Work data",
    zipCode: "ZIP code",
  },
  navigation: {
    member: "Members",
  },
  roleModules: {
    member: "Members",
  },
  buttons: {
    showQrCode: "Show HelvetiCA QR",
  },
};
