import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { koruBatch } from "@/core/modules/batch";
import { SearchModuleInterface } from "@/core/modules/search/interfaces/SearchModule.interface";
import { User } from "../objects/User";
import { userModel } from "../models/User.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const userSearch: SearchModuleInterface = {
  collectionName: "users",
  format: (firestoreDocument: FirestoreDocument) => (firestoreDocument as User).fullName ?? "-",
  getLink: (firestoreDocument: FirestoreDocument) => `/users/${firestoreDocument.id}/edit`,
  icon: "user",
  localeModule: "user",
  roleModule: "user",
  search: (text: string) => userModel.searchDocuments(text),
  setSearchKeys: processSearchKeys,
  title: "user.user",
};

async function processSearchKeys(): Promise<void> {
  try {
    const users: User[] = await userModel.getDocuments();
    for (const user of users) {
      user.setSearchKeys();
      koruBatch.update(getCollectionReference("users").doc(user.id), { searchKeys: user.searchKeys });
    }

    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
