import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/User.model";

export const checkInstallStatus = async (): Promise<string> => {
  try {
    const users: User[] = await userModel.getDocuments();

    return users.length > 0 ? "installed" : "installNeeded";
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
