export class StringField {
  public static fromFirestore(value: unknown): string | undefined {
    if (value === null || value === undefined) return undefined;

    return value as string;
  }

  public static toFirestore(value: string | undefined): string | null {
    if (value === null || value === undefined) return null;

    return value;
  }
}
