export const en = {
  notification: {
    alert: "You have {count} new notification | You have {count} new notifications",
    messages: {
      backup: {
        failed: "It was not possible to complete the backup",
        success: "Backup completed successfully",
      },
      cloudEvent: "A new cloud event has been generated",
      releaseCreated: "Version {message} has been released",
      userCreated: "A user has been created for {message}",
    },
    none: "No unread notification",
    notifications: "Notifications",
    types: {
      backup: "Backup",
      cloudEvent: "Cloud events",
      releaseCreated: "New releases",
      userCreated: "New users",
    },
  },
};
