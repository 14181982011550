export const en = {
  status: {
    isSkyguideEmployee: "Skyguide employee",
    isSkyguideEmployeeError: "Select a value",
    status: "Status",
    statuses: "Statuses",
    name: "Name",
    nameError: "Enter a valid name",
  },
  navigation: {
    status: "Statuses",
  },
  roleModules: {
    status: "Statuses",
  },
};
