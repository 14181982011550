import { koruBatch } from "@/core/modules/batch";
import { Unit } from "@/features/modules/unit/objects/Unit";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const reorderUnits = async (units: Unit[]): Promise<void> => {
  try {
    let order = 1;
    for (const unit of units) {
      koruBatch.update(getCollectionReference("units").doc(unit.id), { order: order });
      order++;
    }
    return koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
