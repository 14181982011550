export const fr = {
  emergencyDoc: {
    emergencyDoc: "Document d'urgence",
    emergencyDocs: "Documents d'urgence",
    expiration: "Expiration",
    files: "Fichiers",
    noFiles: "Aucun fichier",
    title: "Titre",
    titleError: "Entrez un titre valide",
    uploadFile: "Télécharger le fichier",
  },
  navigation: {
    emergencyDoc: "Documents d'urgence",
  },
  roleModules: {
    emergencyDoc: "Documents d'urgence",
  },
};
