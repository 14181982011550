import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Gender } from "./Gender";
import { koruConfig } from "@/core/modules/config";
import { LinkedGroup } from "@/features/modules/group/objects/LinkedGroup";
import { LinkedStatus } from "@/features/modules/status/objects/LinkedStatus";
import { LinkedUnit } from "@/features/modules/unit/objects/LinkedUnit";
import { MemberInterface } from "../interfaces/Member.interface";
import { NotificationMode } from "./NotificationMode";

import { uniqueId } from "@/core/plugins/unique-id";

import { objectToOrderedArray, orderedArrayToObject } from "@/core/modules/helpers";

import {
  AddressField,
  ArrayByKeyField,
  BooleanField,
  DateField,
  EnumField,
  NumberField,
  ObjectField,
  StringArrayField,
  StringField,
} from "@/core/fields";

export class Member extends FirestoreDocument implements MemberInterface {
  public helveticaId: string | undefined = undefined;
  public helveticaIdNumber = 0;
  public certificateUrl: string | undefined = uniqueId();
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public gender: Gender = Gender.Male;
  public birthDate: Date | undefined = undefined;
  public address: AddressField = new AddressField();
  public phone: string | undefined = undefined;
  public email: string | undefined = undefined;
  public secondaryEmail: string | undefined = undefined;
  public notificationMode: NotificationMode = NotificationMode.None;
  public status: LinkedStatus | undefined = undefined;
  public units: Record<string, LinkedUnit> = {};
  public mainUnit: LinkedUnit | undefined = undefined;
  public skyguideEmployeeId: string | undefined = undefined;
  public skyguideEntryDate: Date | undefined = undefined;
  public solidarityConsent = false;
  public spokenLanguages: string[] = [];
  public groups: Record<string, LinkedGroup> = {};
  public picture: string | undefined = undefined;
  public notes: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Member {
    super.fromFirestore(data, id);

    this.helveticaId = StringField.fromFirestore(data.helveticaId);
    this.helveticaIdNumber = NumberField.fromFirestore(data.helveticaIdNumber);
    this.certificateUrl = StringField.fromFirestore(data.certificateUrl);
    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.gender = EnumField.fromFirestore<Gender>(data.gender, Object.values(Gender), Gender.Male);
    this.birthDate = DateField.fromFirestore(data.birthDate);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.email = StringField.fromFirestore(data.email);
    this.secondaryEmail = StringField.fromFirestore(data.secondaryEmail);
    this.notificationMode = EnumField.fromFirestore<NotificationMode>(data.notificationMode, Object.values(NotificationMode), NotificationMode.None);
    this.status = ObjectField.fromFirestore<LinkedStatus>(data.status, (value) => new LinkedStatus(value));
    this.units = ArrayByKeyField.fromFirestore<LinkedUnit>(data.units, (value) => new LinkedUnit(value));
    this.mainUnit = ObjectField.fromFirestore<LinkedUnit>(data.mainUnit, (value) => new LinkedUnit(value));
    this.skyguideEmployeeId = StringField.fromFirestore(data.skyguideEmployeeId);
    this.skyguideEntryDate = DateField.fromFirestore(data.skyguideEntryDate);
    this.solidarityConsent = BooleanField.fromFirestore(data.solidarityConsent);
    this.spokenLanguages = StringArrayField.fromFirestore(data.spokenLanguages);
    this.groups = ArrayByKeyField.fromFirestore<LinkedGroup>(data.groups, (value) => new LinkedGroup(value));
    this.picture = StringField.fromFirestore(data.picture);
    this.notes = StringField.fromFirestore(data.notes);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.helveticaId = StringField.toFirestore(this.helveticaId);
    firestoreData.helveticaIdNumber = NumberField.toFirestore(this.helveticaIdNumber);
    firestoreData.certificateUrl = StringField.toFirestore(this.certificateUrl);
    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.gender = EnumField.toFirestore(this.gender, Gender.Male);
    firestoreData.birthDate = DateField.toFirestore(this.birthDate);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.secondaryEmail = StringField.toFirestore(this.secondaryEmail);
    firestoreData.notificationMode = EnumField.toFirestore(this.notificationMode, NotificationMode.None);
    firestoreData.status = ObjectField.toFirestore(this.status, (value) => value.toFirestore());
    firestoreData.units = ArrayByKeyField.toFirestore(this.units, (value) => value.toFirestore());
    firestoreData.mainUnit = ObjectField.toFirestore(this.mainUnit, (value) => value.toFirestore());
    firestoreData.skyguideEmployeeId = StringField.toFirestore(this.skyguideEmployeeId);
    firestoreData.skyguideEntryDate = DateField.toFirestore(this.skyguideEntryDate);
    firestoreData.solidarityConsent = BooleanField.toFirestore(this.solidarityConsent);
    firestoreData.spokenLanguages = StringArrayField.toFirestore(this.spokenLanguages);
    firestoreData.groups = ArrayByKeyField.toFirestore(this.groups, (value) => value.toFirestore());
    firestoreData.picture = StringField.toFirestore(this.picture);
    firestoreData.notes = StringField.toFirestore(this.notes);

    // if (this.id === "new") firestoreData.sendMail = true; TODO

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.helveticaId !== undefined) this.searchKeys.push(this.helveticaId.toLowerCase());
    if (this.lastName !== undefined) this.searchKeys.push(this.lastName.toLowerCase());
    if (this.firstName !== undefined) this.searchKeys.push(this.firstName.toLowerCase());
    if (this.email !== undefined) this.searchKeys.push(this.email.toLowerCase());
  }

  public setFullName(): void {
    if (this.lastName !== undefined && this.firstName !== undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
    } else {
      this.fullName = undefined;
    }
  }

  public setHelveticaId(): void {
    this.helveticaId = `2021${this.helveticaIdNumber.toString().padStart(4, "0")}`;
  }

  public getLinkedUnits(): LinkedUnit[] {
    return objectToOrderedArray<LinkedUnit>(this.units);
  }

  public setLinkedUnits(linkedUnits: LinkedUnit[]): void {
    this.units = orderedArrayToObject<LinkedUnit>(linkedUnits);
  }

  public addLinkedUnit(linkedUnit: LinkedUnit): void {
    this.units[linkedUnit.id] = linkedUnit;
  }

  public removeLinkedUnit(linkedUnit: LinkedUnit): void {
    delete this.units[linkedUnit.id];
  }

  public getLinkedGroups(): LinkedGroup[] {
    return objectToOrderedArray<LinkedGroup>(this.groups);
  }

  public setLinkedGroups(linkedGroups: LinkedGroup[]): void {
    this.groups = orderedArrayToObject<LinkedGroup>(linkedGroups);
  }

  public addLinkedGroup(linkedGroup: LinkedGroup): void {
    this.groups[linkedGroup.id] = linkedGroup;
  }

  public removeLinkedGroup(linkedGroup: LinkedGroup): void {
    delete this.groups[linkedGroup.id];
  }

  public getQrCodeUrl(): string {
    if (this.helveticaId === undefined || this.certificateUrl === undefined) {
      return `${koruConfig.settings.certificateBaseUrl}/error`;
    }
    return `${koruConfig.settings.certificateBaseUrl}/${this.certificateUrl}`;
  }
}
