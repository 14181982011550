import { RouteRecordRaw } from "vue-router";

export const candidateRoutes: Array<RouteRecordRaw> = [
  {
    path: "/candidates",
    name: "CandidateList",
    component: () => import("../views/CandidateList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "candidate",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/candidates/:id/edit",
    name: "CandidateEdit",
    component: () => import("../views/CandidateEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "candidate",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
  {
    path: "/candidates/:id/:section?",
    name: "CandidateView",
    component: () => import("../views/CandidateView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "candidate",
      requiredRights: ["readAll", "readOwn"],
    },
  },
];
