import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { Group } from "../objects/Group";
import { GroupModelInterface } from "../interfaces/GroupModel.interface";

export class GroupModel extends FirestoreModel<Group> implements GroupModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Group, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Group[]> {
    return super.getDocuments("name", "asc");
  }

  public async getDocument(groupId: string): Promise<Group> {
    return super.getDocument(groupId);
  }

  public async createDocument(group: Group, logAction = true): Promise<string> {
    return super.createDocument(group, logAction);
  }

  public async updateDocument(group: Group, logAction = true): Promise<void> {
    return super.updateDocument(group, logAction);
  }

  public async deleteDocument(group: Group, logAction = true): Promise<void> {
    const factoryGroups: string[] = ["emergency-officers"];
    if (factoryGroups.includes(group.id)) throw new Error("factoryGroup");

    return super.deleteDocument(group, logAction);
  }

  public async searchDocuments(): Promise<Group[]> {
    return Promise.resolve([]);
  }
}

export const groupModel: GroupModel = new GroupModel("groups", undefined, true, "featuresGroupBeforeDelete");
