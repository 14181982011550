export const en = {
  emergencyDoc: {
    emergencyDoc: "Emergency document",
    emergencyDocs: "Emergency documents",
    expiration: "Expiration",
    files: "Files",
    noFiles: "No files",
    title: "Title",
    titleError: "Enter a valid title",
    uploadFile: "Upload file",
  },
  navigation: {
    emergencyDoc: "Emergency documents",
  },
  roleModules: {
    emergencyDoc: "Emergency documents",
  },
};
