import { goToErrorPage, showToastWithErrorType, showToastWithSuccessType } from ".";

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function createRange(from: number, to: number, step = 1): number[] {
  const array: number[] = [];
  for (let i = from; i <= to; i = i + step) {
    array.push(i);
  }
  return array;
}

export function getObjectValueWithField(object: unknown, field: string): unknown {
  const fields: string[] = field.split(".");

  if (fields.length == 1) {
    return (<never>object)[field];
  } else {
    let inceptionObject: unknown = object;
    for (const field of fields) {
      inceptionObject = (<never>inceptionObject)[field];
    }
    return inceptionObject;
  }
}

export function orderObjectByKeys(object: Record<string, unknown>): Record<string, unknown> {
  return Object.keys(object)
    .sort()
    .reduce((obj: Record<string, unknown>, key: string) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

export function nl2br(text: string) {
  if (text == undefined) return "";
  const breakTag = "<br />";
  return (text + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
}

export function removeFromArrayByObjectValueWithField(array: unknown[], object: unknown, field: string): void {
  array.splice(
    (array as Record<string, unknown>[]).findIndex((item) => item[field] === getObjectValueWithField(object, field)),
    1
  );
}

export function setObjectValueWithField(object: unknown, field: string, value: unknown): void {
  Object.assign(object as Record<string, unknown>, { [field]: value });
}

export function simpleCellSave(event: Record<string, unknown>): void {
  const { data, newValue, field } = event;

  (data as Record<string, unknown>)[field as string] = newValue;
}

export async function tryOrError(action: () => Promise<void>, final?: () => void): Promise<void> {
  try {
    await action();
  } catch (error: unknown) {
    console.log((error as Error).message);
    goToErrorPage((error as Error).message);
  } finally {
    if (final != undefined) {
      final();
    }
  }
}

export async function tryOrToast(
  action: () => Promise<void>,
  message: string,
  t: (entry: string) => string,
  final?: () => void,
  showSuccess?: boolean
): Promise<void> {
  if (showSuccess == undefined) showSuccess = true;
  try {
    await action();

    if (showSuccess) showToastWithSuccessType(message, t);
  } catch (error: unknown) {
    console.log((error as Error).message);
    showToastWithErrorType((error as Error).message, message, t);
  } finally {
    if (final != undefined) final();
  }
}

export function validationHasErrorAtFieldAndIndex(validation: Record<string, unknown>, field: string, index: number): boolean {
  const each: Record<string, unknown> = validation.$each as Record<string, unknown>;
  const response: Record<string, unknown> = each.$response as Record<string, unknown>;
  const errors: Record<string, unknown>[] = response.$errors as Record<string, unknown>[];
  const fieldErrors: Record<string, unknown>[] = errors[index][field] as Record<string, unknown>[];

  return fieldErrors.length > 0;
}
