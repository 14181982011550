
import { computed, defineComponent, ref } from "vue";
import { useToast } from "primevue/usetoast";

import { koruApp } from "@/main";
import { koruEventBus } from "@/core/modules/event-bus";
import { koruStoreTypes } from "@/core/modules/store/types";
import { ThemeInterface } from "@/core/modules/theme/interfaces/Theme.interface";
import { themeStoreTypes } from "@/core/modules/theme/store/types";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruNavigation } from "@/core/modules/navigation";
import { useKoruStore } from "@/core/modules/store";
import { useThemeStore } from "@/core/modules/theme/store";

import Footer from "./Footer.vue";
import Menu from "./Menu.vue";
import SearchComponent from "@/core/modules/search/components/SearchComponent.vue";
import TopBar from "./TopBar.vue";

export default defineComponent({
  name: "Bedrock",
  components: {
    Footer,
    Menu,
    SearchComponent,
    TopBar,
  },
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();
    const koruNavigation = useKoruNavigation();
    const koruStore = useKoruStore();
    const themeStore = useThemeStore();
    const toast = useToast();

    const getColorScheme = computed(() => {
      const selectedTheme: ThemeInterface = themeStore.getter(themeStoreTypes.getters.getSelectedTheme);
      return selectedTheme.name;
    });

    const layoutMode = koruConfig.layout.mode;
    const menuTheme = koruConfig.layout.menuTheme;
    const menu = koruNavigation.menuItems;
    const menuActive = ref(false);
    const overlayMenuActive = ref(false);
    const staticMenuDesktopInactive = ref(false);
    const staticMenuMobileActive = ref(false);
    const menuClick = ref(false);
    const searchActive = ref(false);
    const searchClick = ref(false);
    const userMenuClick = ref(false);
    const themeMenuClick = ref(false);
    const langMenuClick = ref(false);
    const topbarUserMenuActive = ref(false);
    const topbarThemeMenuActive = ref(false);
    const topbarLangMenuActive = ref(false);
    const notificationMenuClick = ref(false);
    const topbarNotificationMenuActive = ref(false);
    const rightMenuClick = ref(false);
    const rightMenuActive = ref(false);
    const configActive = ref(false);
    const configClick = ref(false);

    const containerClass = computed(() => {
      const selectedTheme: ThemeInterface = themeStore.getter(themeStoreTypes.getters.getSelectedTheme);
      const colorScheme = selectedTheme.name;

      return [
        "layout-wrapper",
        {
          "layout-overlay": layoutMode === "overlay",
          "layout-static": layoutMode === "static",
          "layout-slim": layoutMode === "slim",
          "layout-sidebar-dim": colorScheme === "dim",
          "layout-sidebar-dark": colorScheme === "dark",
          "layout-overlay-active": overlayMenuActive.value,
          "layout-mobile-active": staticMenuMobileActive.value,
          "layout-static-inactive": staticMenuDesktopInactive.value && layoutMode === "static",
          "p-input-filled": koruApp.config.globalProperties.$appState.inputStyle === "filled",
          "p-ripple-disabled": koruApp.config.globalProperties.$primevue.config.ripple,
        },
        menuTheme,
      ];
    });

    return {
      containerClass,
      koruConfig,
      koruStore,
      koruStoreTypes,
      menuActive,
      layoutMode,
      getColorScheme,
      menuTheme,
      overlayMenuActive,
      staticMenuDesktopInactive,
      staticMenuMobileActive,
      menuClick,
      searchActive,
      searchClick,
      userMenuClick,
      themeMenuClick,
      langMenuClick,
      t,
      topbarUserMenuActive,
      topbarThemeMenuActive,
      topbarLangMenuActive,
      notificationMenuClick,
      topbarNotificationMenuActive,
      rightMenuClick,
      rightMenuActive,
      configActive,
      configClick,
      menu,
      toast,
    };
  },
  watch: {
    $route(): void {
      this.menuActive = false;
      this.toast.removeAllGroups();
    },
  },
  methods: {
    onDocumentClick(): void {
      if (!this.searchClick && this.searchActive) {
        this.onSearchHide();
      }
      if (!this.userMenuClick) {
        this.topbarUserMenuActive = false;
      }
      if (!this.themeMenuClick) {
        this.topbarThemeMenuActive = false;
      }
      if (!this.langMenuClick) {
        this.topbarLangMenuActive = false;
      }
      if (!this.notificationMenuClick) {
        this.topbarNotificationMenuActive = false;
      }
      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }
      if (!this.menuClick) {
        if (this.isSlim()) {
          koruEventBus.emit("reset-active-index");
          this.menuActive = false;
        }
        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }
        this.unblockBodyScroll();
      }
      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }
      this.searchClick = false;
      this.configClick = false;
      this.userMenuClick = false;
      this.themeMenuClick = false;
      this.langMenuClick = false;
      this.rightMenuClick = false;
      this.notificationMenuClick = false;
      this.menuClick = false;
    },
    onMenuClick(): void {
      this.menuClick = true;
    },
    onMenuButtonClick(event: Event): void {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.topbarThemeMenuActive = false;
      this.topbarLangMenuActive = false;
      this.topbarNotificationMenuActive = false;
      this.rightMenuActive = false;
      if (this.isOverlay()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }
      event.preventDefault();
    },
    onMenuItemClick(event: Record<string, Record<string, unknown>>): void {
      if (!event.item.items) {
        koruEventBus.emit("reset-active-index");
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isSlim()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick(): void {
      this.menuActive = !this.menuActive;
    },
    onTopbarUserMenuButtonClick(event: Event): void {
      this.userMenuClick = true;
      this.topbarUserMenuActive = !this.topbarUserMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarThemeMenuButtonClick(event: Event): void {
      this.themeMenuClick = true;
      this.topbarThemeMenuActive = !this.topbarThemeMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarLangMenuButtonClick(event: Event): void {
      this.langMenuClick = true;
      this.topbarLangMenuActive = !this.topbarLangMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.notificationMenuClick = true;
      this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    toggleSearch(): void {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    onSearchClick(): void {
      this.searchClick = true;
    },
    onSearchHide(): void {
      this.searchActive = false;
      this.searchClick = false;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onRightMenuButtonClick(event: Event): void {
      this.rightMenuClick = true;
      this.rightMenuActive = !this.rightMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onConfigClick(): void {
      this.configClick = true;
    },
    onConfigButtonClick(): void {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu(): void {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll(): void {
      if (document.body.classList) {
        document.body.classList.add("blocked-scroll");
      } else {
        document.body.className += " blocked-scroll";
      }
    },
    unblockBodyScroll(): void {
      if (document.body.classList) {
        document.body.classList.remove("blocked-scroll");
      } else {
        document.body.className = document.body.className.replace(
          new RegExp("(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );
      }
    },
    isSlim(): boolean {
      return this.layoutMode === "slim";
    },
    isOverlay(): boolean {
      return this.layoutMode === "overlay";
    },
    isDesktop(): boolean {
      return window.innerWidth > 991;
    },
  },
});
