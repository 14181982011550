import { MailMessage } from "../objects/MailMessage";
import { MailMessageModelInterface } from "../interfaces/MailMessageModel.interface";
import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";

export class MailMessageModel extends FirestoreModel<MailMessage> implements MailMessageModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(MailMessage, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<MailMessage[]> {
    return Promise.resolve([]);
  }

  public async getDocument(): Promise<MailMessage> {
    return Promise.resolve(new MailMessage());
  }

  public async createDocument(mailMessage: MailMessage): Promise<string> {
    return super.createDocument(mailMessage, false);
  }

  public async updateDocument(): Promise<void> {
    return Promise.resolve();
  }

  public async deleteDocument(): Promise<void> {
    return Promise.resolve();
  }

  public async searchDocuments(): Promise<MailMessage[]> {
    return Promise.resolve([]);
  }
}

export const mailMessageModel: MailMessageModel = new MailMessageModel("mailMessages");
