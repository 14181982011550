import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/remote-config";

import { FirebaseConfigInterface } from "./interfaces/FirebaseConfig.interface";
import { koruConfig } from "@/core/modules/config";

export const firebaseInitialize = (): void => {
  const firebaseConfig: FirebaseConfigInterface = {
    apiKey: koruConfig.firebase.apiKey,
    appId: koruConfig.firebase.appId,
    authDomain: koruConfig.firebase.authDomain,
    clientEmail: koruConfig.firebase.clientEmail,
    databaseURL: koruConfig.firebase.databaseURL,
    messagingSenderId: koruConfig.firebase.messagingSenderId,
    projectId: koruConfig.firebase.projectId,
    region: koruConfig.firebase.region,
    storageBucket: koruConfig.firebase.storageBucket,
  };

  firebase.initializeApp(firebaseConfig);
  firebase.auth();
  firebase.firestore();

  firebase.auth().setPersistence(process.env.NODE_ENV === "test" ? firebase.auth.Auth.Persistence.NONE : firebase.auth.Auth.Persistence.LOCAL);
};

firebaseInitialize();

export const firebaseAuth = firebase.auth();
export const firebaseFirestore = firebase.firestore();
export const firebaseFunctions = firebase.app().functions(koruConfig.firebase.region);
export const firebaseStorage = firebase.storage();
