export const fr = {
  log: {
    action: "Action",
    date: "Date",
    log: "Journal des actions",
    type: "Type",
    types: {
      error: "Erreur",
      info: "Action",
      warning: "Avertissement",
    },
    user: "Utilisateur",
  },
  navigation: {
    log: "Journal des actions",
  },
  roleModules: {
    log: "Journal des actions",
  },
};
