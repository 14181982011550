import { RouteRecordRaw } from "vue-router";

export const unitRoutes: Array<RouteRecordRaw> = [
  {
    path: "/units/:id/edit",
    name: "UnitEdit",
    component: () => import("../views/UnitEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "unit",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
