import { LocaleModuleInterface } from "@/core/modules/locale/interfaces/LocaleModule.interface";

import { de } from "./de.ts";
import { en } from "./en.ts";
import { fr } from "./fr.ts";
import { it } from "./it.ts";

export const installerLocaleModule: LocaleModuleInterface = {
  name: "installer",
  messages: { de: de, en: en, fr: fr, it: it },
};
