export const en = {
  workbench: {
    action: "Action",
    actionSelect: "Select an action",
    collections: "Collections",
    collectionsSelect: "Select one or more collections",
    file: "File",
    fileSelect: "Select a file to import",
    go: "Go",
    modules: {
      deleteData: "Delete one or more collections",
      exportData: "Export one or more collections",
      importData: "Import collections from backup",
      sendTestMail: "Send a test mail",
      setSearchKeys: "Set search keys",
    },
    types: {
      core: "Core",
      features: "Features",
    },
    workbench: "Workbench",
  },
  navigation: {
    workbench: "Workbench",
  },
  roleModules: {
    workbench: "Workbench",
  },
};
