import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { koruBatch } from "@/core/modules/batch";
import { SearchModuleInterface } from "@/core/modules/search/interfaces/SearchModule.interface";
import { Role } from "../objects/Role";
import { roleModel } from "../models/Role.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const roleSearch: SearchModuleInterface = {
  collectionName: "roles",
  format: (firestoreDocument: FirestoreDocument) => (firestoreDocument as Role).name ?? "-",
  getLink: (firestoreDocument: FirestoreDocument) => `/roles/${firestoreDocument.id}/edit`,
  icon: "tags",
  localeModule: "role",
  roleModule: "role",
  search: (text: string) => roleModel.searchDocuments(text),
  setSearchKeys: processSearchKeys,
  title: "role.role",
};

async function processSearchKeys(): Promise<void> {
  try {
    const roles: Role[] = await roleModel.getDocuments();
    for (const role of roles) {
      role.setSearchKeys();
      koruBatch.update(getCollectionReference("roles").doc(role.id), { searchKeys: role.searchKeys });
    }

    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
