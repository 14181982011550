import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { Group } from "../objects/Group";
import { koruBatch } from "@/core/modules/batch";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const groupDummy: DummyModuleInterface = {
  collectionName: "groups",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    // factory groups
    const group: Group = new Group();
    group.name = "Emergency Officers";
    koruBatch.set(getCollectionReference("groups").doc("emergency-officers"), group.toFirestore());

    for (let i = 1; i <= 5; i++) {
      const group: Group = new Group();
      group.name = `Group ${i}`;

      group.setTimestampFields("create");
      koruBatch.set(getCollectionReference("groups").doc(), group.toFirestore());
    }
  },
};
