export const de = {
  group: {
    info: "Informationen",
    group: "Gruppe",
    groups: "Gruppen",
    name: "Name",
    nameError: "Geben Sie einen gültigen Namen ein",
  },
  navigation: {
    group: "Gruppen",
  },
  roleModules: {
    group: "Gruppen",
  },
  buttons: {
    removeFromGroup: "Aus Gruppe entfernen",
  },
  toast: {
    error: {
      removeFromGroup: "Mitglied kann nicht aus Gruppe entfernt werden",
      factoryGroup: "Gruppe ist gesperrt",
    },
    success: {
      removeFromGroup: "Mitglied aus Gruppe entfernt",
    },
    warning: {},
  },
};
