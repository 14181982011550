export const fr = {
  status: {
    isSkyguideEmployee: "Skyguide employé",
    isSkyguideEmployeeError: "Sélectionnez une valeur",
    status: "Statut",
    statuses: "Statuts",
    name: "Nom",
    nameError: "Entrez un nom valide",
  },
  navigation: {
    status: "Statuts",
  },
  roleModules: {
    status: "Statuts",
  },
};
