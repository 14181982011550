export const de = {
  workbench: {
    action: "Aktion",
    actionSelect: "Wähle eine Aktion aus",
    collections: "Sammlungen",
    collectionsSelect: "Eine oder mehrere Sammlungen auswählen",
    file: "Datei",
    fileSelect: "Datei zum Importieren auswählen",
    go: "Los",
    modules: {
      deleteData: "Eine oder mehrere Sammlungen löschen",
      exportData: "Eine oder mehrere Sammlungen exportieren",
      importData: "Sammlungen aus einem Backup importieren",
      sendTestMail: "Sende eine Testmail",
      setSearchKeys: "Suchschlüssel festlegen",
    },
    types: {
      core: "Kern",
      features: "Eigenschaften",
    },
    workbench: "Werkbank",
  },
  navigation: {
    workbench: "Werkbank",
  },
  roleModules: {
    workbench: "Werkbank",
  },
};
