export const de = {
  status: {
    isSkyguideEmployee: "Skyguide angestellter",
    isSkyguideEmployeeError: "Wähle einen Wert",
    status: "Status",
    statuses: "Status",
    name: "Name",
    nameError: "Geben Sie einen gültigen Namen ein",
  },
  navigation: {
    status: "Status",
  },
  roleModules: {
    status: "Status",
  },
};
