import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { Unit } from "../objects/Unit";
import { UnitModelInterface } from "../interfaces/UnitModel.interface";

import { countUnits, reorderUnits } from "./methods";

export class UnitModel extends FirestoreModel<Unit> implements UnitModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Unit, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Unit[]> {
    return super.getDocuments("order", "asc");
  }

  public async getDocument(unitId: string): Promise<Unit> {
    return super.getDocument(unitId);
  }

  public async createDocument(unit: Unit, logAction = true): Promise<string> {
    return super.createDocument(unit, logAction);
  }

  public async updateDocument(unit: Unit, logAction = true): Promise<void> {
    return super.updateDocument(unit, logAction);
  }

  public async deleteDocument(unit: Unit, logAction = true): Promise<void> {
    return super.deleteDocument(unit, logAction);
  }

  public async searchDocuments(): Promise<Unit[]> {
    return Promise.resolve([]);
  }

  /* custom methods */

  public async countUnits(): Promise<number> {
    return countUnits();
  }

  public async reorderUnits(units: Unit[]): Promise<void> {
    return reorderUnits(units);
  }
}

export const unitModel: UnitModel = new UnitModel("units", undefined, true, "featuresUnitBeforeDelete");
