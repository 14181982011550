export const de = {
  dummy: {
    collections: "Sammlungen",
    collectionsSelect: "Eine oder mehrere Sammlungen auswählen",
    deleteBefore: "Vorhandene Daten löschen",
    dummy: "Dummy Data",
    go: "Los",
    subtitle: "Laden Sie Daten, um die Anwendung zu testen",
  },
  navigation: {
    dummy: "Dummy Data",
  },
  roleModules: {
    dummy: "Dummy Data",
  },
  toast: {
    error: {
      uploadedDataNotFound: "Daten zum Importieren hochladen",
    },
    success: {},
    warning: {},
  },
};
