export const en = {
  stat: {
    choose: "Choose a statistic to display",
    stat: "Stats",
  },
  navigation: {
    stat: "Stats",
  },
  roleModules: {
    stat: "Stats",
  },
};
