export const fr = {
  stat: {
    choose: "Choisir une statistique à afficher",
    stat: "Statistiques",
  },
  navigation: {
    stat: "Statistiques",
  },
  roleModules: {
    stat: "Statistiques",
  },
};
