import { registerDummyModule } from "@/core/modules/dummy/helpers";

import { candidateDummy } from "@/features/modules/candidate/dummy";
import { groupDummy } from "@/features/modules/group/dummy";
import { languageDummy } from "@/features/modules/language/dummy";
import { memberDummy } from "@/features/modules/member/dummy";
import { sectionDummy } from "@/features/modules/section/dummy";
import { statusDummy } from "@/features/modules/status/dummy";
import { unitDummy } from "@/features/modules/unit/dummy";

export const registerFeaturesDummyModules = () => {
  registerDummyModule(languageDummy);
  registerDummyModule(statusDummy);
  registerDummyModule(sectionDummy);
  registerDummyModule(unitDummy);
  registerDummyModule(groupDummy);
  registerDummyModule(candidateDummy);
  registerDummyModule(memberDummy);
};
