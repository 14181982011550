export const en = {
  cloudEvent: {
    cloudEvent: "Cloud event",
    cloudEvents: "Cloud events",
    createdAt: "Date and time",
    details: "Details",
    function: "Function",
    noDetails: "No details available",
    type: "Type",
    types: {
      exception: "Exception",
      report: "Report",
    },
  },
  buttons: {
    showDetails: "Show details",
  },
  navigation: {
    cloudEvent: "Cloud events",
  },
  roleModules: {
    cloudEvent: "Cloud events",
  },
};
