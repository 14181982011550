import { ActionContext } from "vuex";

import { EmergencyDocStateInterface } from "@/features/modules/emergency-doc/interfaces/EmergencyDocState.interface";
import { emergencyDocStoreTypes } from "../types";
import { koruConfig } from "@/core/modules/config";
import { KoruFile } from "@/core/modules/file/objects/KoruFile";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { koruStorage } from "@/core/modules/storage";

import { uniqueId } from "@/core/plugins/unique-id";

export const uploadFile = async (context: ActionContext<EmergencyDocStateInterface, KoruStateInterface>, file: File): Promise<KoruFile> => {
  try {
    const extension = file.name.split(".").pop();
    const uniqueName = uniqueId() + "." + extension;

    const downloadUrl: string = await koruStorage.upload(koruConfig.storage.folders.emergencyDocs + uniqueName, file, (progress: number) => {
      context.commit(emergencyDocStoreTypes.mutations.setUploadProgressValue, progress);
    });
    const emergencyDocFile: KoruFile = new KoruFile();
    emergencyDocFile.uniqueName = uniqueName;
    emergencyDocFile.displayName = file.name;
    emergencyDocFile.type = extension?.toUpperCase() || "";
    emergencyDocFile.size = file.size;
    emergencyDocFile.url = downloadUrl;

    return emergencyDocFile;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
