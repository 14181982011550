import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Member } from "@/features/modules/member/objects/Member";
import { memberModel } from "../Member.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const getMembersByGroup = async (groupId: string): Promise<Member[]> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<Member> = await getCollectionReference(memberModel.collectionName)
      .withConverter(memberModel.firestoreConverter)
      .where(`groups.${groupId}.id`, "==", groupId)
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
