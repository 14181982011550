import { RouteRecordRaw } from "vue-router";

export const cloudEventRoutes: Array<RouteRecordRaw> = [
  {
    path: "/cloud-events",
    name: "CloudEventList",
    component: () => import("../views/CloudEventList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "cloudEvent",
      requiredRights: ["readAll", "readOwn"],
    },
  },
];
