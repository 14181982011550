import { RouteRecordRaw } from "vue-router";

export const sectionRoutes: Array<RouteRecordRaw> = [
  {
    path: "/sections/:id/edit",
    name: "SectionEdit",
    component: () => import("../views/SectionEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "section",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
