import { Notification } from "../objects/Notification";
import { NotificationModelInterface } from "../interfaces/NotificationModel.interface";
import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";

import { getNotificationsByOwner, getUnreadNotifications, markNotificationAsDeleted, markNotificationAsRead } from "./methods";

export class NotificationModel extends FirestoreModel<Notification> implements NotificationModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Notification, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Notification[]> {
    return super.getDocuments("createdAt", "desc");
  }

  public async getDocument(notificationId: string): Promise<Notification> {
    return super.getDocument(notificationId);
  }

  public async createDocument(notification: Notification): Promise<string> {
    return super.createDocument(notification, false);
  }

  public async updateDocument(notification: Notification): Promise<void> {
    if (notification.owners.length === 0 && notification.unread.length === 0) {
      return super.deleteDocument(notification, false);
    }
    return super.updateDocument(notification, false);
  }

  public async deleteDocument(notification: Notification): Promise<void> {
    return super.deleteDocument(notification, false);
  }

  public async searchDocuments(): Promise<Notification[]> {
    return Promise.resolve([]);
  }

  /* custom methods */

  public async getNotificationsByOwner(userId: string): Promise<Notification[]> {
    return getNotificationsByOwner(userId);
  }

  public async getUnreadNotifications(userId: string): Promise<Notification[]> {
    return getUnreadNotifications(userId);
  }

  public async markNotificationAsDeleted(notificationId: string, userId: string): Promise<void> {
    return markNotificationAsDeleted(notificationId, userId);
  }

  public async markNotificationAsRead(notificationId: string, userId: string): Promise<void> {
    return markNotificationAsRead(notificationId, userId);
  }
}

export const notificationModel: NotificationModel = new NotificationModel("notifications", undefined, false);
