export const en = {
  language: {
    flag: "Flag",
    language: "Language",
    languages: "Languages",
    name: "Name",
    nameError: "Enter a valid name",
    shortName: "Short name",
    shortNameError: "Enter a valid short name",
  },
  navigation: {
    language: "Languages",
  },
  roleModules: {
    language: "Languages",
  },
};
