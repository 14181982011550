import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Unit } from "@/features/modules/unit/objects/Unit";
import { unitModel } from "../Unit.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const countUnits = async (): Promise<number> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<Unit> = await getCollectionReference(unitModel.collectionName)
      .withConverter(unitModel.firestoreConverter)
      .get();

    if (snapshot == undefined || snapshot.empty) return 0;

    return snapshot.docs.length;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
