export const fr = {
  backup: {
    backup: "Sauvegardes",
    backups: "Sauvegardes",
    createdAt: "Création",
    createBackup: "Créer une sauvegarde maintenant",
    createBackupDescription: "La sauvegarde est en cours de création et apparaîtra dans la liste dès qu'elle sera terminée",
    createBackupTitle: "Création de sauvegarde",
    type: "Type",
    types: {
      daily: "Quotidien",
      monthly: "Mensuel",
      request: "Sur demande",
      weekly: "Hebdomadaire",
    },
    user: "Utilisateur",
  },
  navigation: {
    backup: "Sauvegardes",
  },
  roleModules: {
    backup: "Sauvegardes",
  },
};
