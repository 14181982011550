import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { LinkedRole } from "@/core/modules/role/objects/LinkedRole";
import { Role } from "@/core/modules/role/objects/Role";
import { roleModel } from "@/core/modules/role/models/Role.model";
import { User } from "../objects/User";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const userDummy: DummyModuleInterface = {
  collectionName: "users",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const role: Role = await roleModel.getDocument("95Clp88EfEyX5fY0DdNS");

    const user: User = new User();

    user.firstName = "Marco";
    user.lastName = "Lisatti";
    user.fullName = "Lisatti Marco";
    user.fullNameReversed = "Marco Lisatti";
    user.email = "marco@spinningtop.it";
    user.role = LinkedRole.createFromRole(role);

    const timestamp = new Date();
    user.createdAt = timestamp;
    user.createdBy = "install";
    user.updatedAt = timestamp;
    user.updatedBy = "install";

    koruBatch.set(getCollectionReference("users").doc("XvbvA13USXeMiGOhOmlX"), user.toFirestore());
  },
};
