export class NumberWithDefaultField {
  public static fromFirestore(value: unknown, defaultValue: number): number {
    if (value === null || value === undefined) return defaultValue;

    return Number(value);
  }

  public static toFirestore(value: number, defaultValue: number): number {
    if (value === null || value === undefined) return defaultValue;

    return Number(value);
  }
}
