import { compareAsc } from "date-fns";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { FirestoreDocumentInterface } from "../interfaces/FirestoreDocument.interface";
import { User } from "@/core/modules/user/objects/User";

import { DateStrictField, StringArrayField, StringStrictField } from "@/core/fields";

export class FirestoreDocument implements FirestoreDocumentInterface {
  public id: string;
  public searchKeys: string[] = [];
  public createdAt: Date = new Date();
  public createdBy = "";
  public updatedAt: Date = new Date();
  public updatedBy = "";

  public constructor(id?: string) {
    this.id = id ?? "new";
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): FirestoreDocument {
    this.id = id !== undefined ? id : StringStrictField.fromFirestore(data.id, "new");

    this.searchKeys = StringArrayField.fromFirestore(data.searchKeys);
    this.createdAt = DateStrictField.fromFirestore(data.createdAt, new Date());
    this.createdBy = StringStrictField.fromFirestore(data.createdBy, "");
    this.updatedAt = DateStrictField.fromFirestore(data.updatedAt, new Date());
    this.updatedBy = StringStrictField.fromFirestore(data.updatedBy, "");

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    return {
      searchKeys: StringArrayField.toFirestore(this.searchKeys),
      createdAt: DateStrictField.toFirestore(this.createdAt),
      createdBy: StringStrictField.toFirestore(this.createdBy),
      updatedAt: DateStrictField.toFirestore(this.updatedAt),
      updatedBy: StringStrictField.toFirestore(this.updatedBy),
    };
  }

  public hasChangedFrom(oldFirestoreDocument: FirestoreDocument): boolean {
    return compareAsc(this.updatedAt, oldFirestoreDocument.updatedAt) != 0 || this.updatedBy != oldFirestoreDocument.updatedBy;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
  }

  public setTimestampFields(mode: "create" | "update"): void {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const now = new Date();
    if (mode == "create") {
      this.createdAt = now;
      this.createdBy = user.id;
    }
    this.updatedAt = now;
    this.updatedBy = user.id;
  }
}
