export const de = {
  log: {
    action: "Aktion",
    date: "Datum",
    log: "Aktionsprotokoll",
    type: "Typ",
    types: {
      error: "Fehler",
      info: "Aktion",
      warning: "Warnung",
    },
    user: "Benutzer",
  },
  navigation: {
    log: "Aktionsprotokoll",
  },
  roleModules: {
    log: "Aktionsprotokoll",
  },
};
