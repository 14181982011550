import { CollectionInterface } from "@/core/modules/config/interfaces/Collection.interface";
import { koruSearch } from "@/core/modules/search";
import { SearchModuleInterface } from "@/core/modules/search/interfaces/SearchModule.interface";
import { WorkbenchModuleInterface } from "../interfaces/WorkbenchModule.interface";
import { WorkbenchModuleType } from "../objects/WorkbenchModuleType";

export const setSearchKeys: WorkbenchModuleInterface = {
  name: "setSearchKeys",
  type: WorkbenchModuleType.Core,
  action: async (selectedCollections: CollectionInterface[]): Promise<void> => {
    return await performAction(selectedCollections);
  },
};

async function performAction(selectedCollections: CollectionInterface[]): Promise<void> {
  try {
    const selectedCollectionsNames: string[] = selectedCollections.map((collection: CollectionInterface) => collection.name);

    const selectedSearchModules: SearchModuleInterface[] = koruSearch.modules.filter((module: SearchModuleInterface) =>
      selectedCollectionsNames.includes(module.collectionName)
    );

    for (const searchModule of selectedSearchModules) {
      await searchModule.setSearchKeys();
    }
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
