import { RouteRecordRaw } from "vue-router";

export const languageRoutes: Array<RouteRecordRaw> = [
  {
    path: "/languages/:id/edit",
    name: "LanguageEdit",
    component: () => import("../views/LanguageEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "language",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
