import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { StatusInterface } from "../interfaces/Status.interface";

import { BooleanField, NumberWithDefaultField, StringField } from "@/core/fields";

export class Status extends FirestoreDocument implements StatusInterface {
  public name: string | undefined = undefined;
  public isSkyguideEmployee = true;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Status {
    super.fromFirestore(data, id);

    this.name = StringField.fromFirestore(data.name);
    this.isSkyguideEmployee = BooleanField.fromFirestore(data.isSkyguideEmployee);
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.isSkyguideEmployee = BooleanField.toFirestore(this.isSkyguideEmployee);
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }
}
