import { FieldInterface } from "../interfaces/Field.interface";

export class Field implements FieldInterface {
  public value: string;
  public type: string | undefined = undefined;

  public constructor(value: string, type: string | undefined) {
    this.value = value;
    this.type = type;
  }
}
