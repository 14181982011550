import { registerStoreModule } from "@/core/modules/store/helpers";

import { authStore } from "@/core/modules/auth/store";
import { dummyStore } from "@/core/modules/dummy/store";
import { exchangeStore } from "@/core/modules/exchange/store";
import { installerStore } from "@/core/modules/installer/store";
import { localeStore } from "@/core/modules/locale/store";
import { searchStore } from "@/core/modules/search/store";
import { themeStore } from "@/core/modules/theme/store";
import { workbenchStore } from "@/core/modules/workbench/store";

export const registerCoreStoreModules = () => {
  registerStoreModule(authStore);
  registerStoreModule(dummyStore);
  registerStoreModule(exchangeStore);
  registerStoreModule(installerStore);
  registerStoreModule(localeStore);
  registerStoreModule(searchStore);
  registerStoreModule(themeStore);
  registerStoreModule(workbenchStore);
};
