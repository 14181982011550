export const fr = {
  user: {
    activeTrue: "Actif",
    activeFalse: "Non actif",
    allUsers: "Tous les utilisateurs",
    email: "E-mail",
    emailAddress: "Adresse e-mail",
    emailAddressError: "Veuillez saisir une adresse e-mail valide et non utilisée",
    firstName: "Prénom",
    firstNameError: "Veuillez entrer un nom valide",
    fullName: "Nom",
    lastName: "Nom de famille",
    lastNameError: "Veuillez saisir un nom de famille valide",
    name: "Nom",
    registration: "Inscription",
    role: "Rôle",
    roleError: "Choisissez un rôle valide",
    roleSelect: "Sélectionner un rôle",
    state: "État",
    user: "Utilisateur",
    users: "Utilisateurs",
  },
  navigation: {
    user: "Utilisateurs",
  },
  roleModules: {
    user: "Utilisateurs",
  },
};
