import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruBatch } from "../batch";
import { KoruLogInterface } from "./interfaces/KoruLog.interface";
import { LinkedUser } from "@/core/modules/user/objects/LinkedUser";
import { Log } from "./objects/Log";
import { User } from "@/core/modules/user/objects/User";

import { getCollectionReference } from "../firestore/helpers";

export const koruLog: KoruLogInterface = {
  logError: (message: string) => saveLog("error", message),
  logInfo: (message: string) => saveLog("info", message),
  logWarning: (message: string) => saveLog("warning", message),
};

export const useKoruLog = (): KoruLogInterface => {
  return koruLog;
};

async function saveLog(type: string, message: string): Promise<void> {
  try {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    const log: Log = new Log();
    log.user = LinkedUser.createFromUser(user);

    log.type = type;
    log.message = message;

    koruBatch.set(getCollectionReference("logs").doc(), log.toFirestore());
    await koruBatch.commit();
  } catch (error: unknown) {
    console.log((error as Error).message);
  }
}
