import { RouteRecordRaw } from "vue-router";

export const searchRoutes: Array<RouteRecordRaw> = [
  {
    path: "/search/:query",
    name: "SearchView",
    component: () => import("../views/SearchView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "log",
      requiredRights: [],
    },
  },
];
