export const deFeaturesLabels = {
  buttons: {},
  gen: {
    helveticaIdQrCode: "HelvetiCA ID QR Code",
  },
  navigation: {
    register: "Registrierung",
  },
  roleModules: {},
  toast: {
    error: {},
    success: {},
    warning: {},
  },
};
