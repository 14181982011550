
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { koruStoreTypes } from "@/core/modules/store/types";
import { RowCommandInterface } from "@/core/modules/layout/interfaces/RowCommand.interface";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

import { getObjectValueWithField, tryOrError, tryOrToast } from "@/core/modules/helpers";
import { userCanRead, userCanUpdate, userCanDelete, userCanCreate } from "@/core/modules/user/helpers";

export default defineComponent({
  name: "KoruSortableBrowser",
  props: {
    canCreate: { type: Boolean, default: true },
    canDelete: { type: Boolean, default: true },
    canEdit: { type: Boolean, default: true },
    canView: { type: Boolean, default: true },
    createPath: { type: String, default: undefined },
    deleteDescriptionField: { type: String },
    deleteAction: { type: Function },
    explicitRequiredModule: { type: String },
    loadAction: { type: Function },
    loadingStop: { type: Boolean, default: true },
    objectName: { type: String, default: "" },
    onReorderAction: { type: Function },
    path: { type: String, default: "" },
    rowCommands: { type: Array },
    suffix: { type: String, default: "list" },
    values: { type: Array },
  },
  emits: ["update:filters", "update:values"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const route = useRoute();
    const requiredModule: string = props.explicitRequiredModule ? props.explicitRequiredModule : (route.meta.requiredModule as string);

    const valuesRef = computed({
      get: () => props.values as unknown[],
      set: (val) => emit("update:values", val),
    });

    onMounted(async () => {
      await tryOrError(
        async () => {
          if (props.loadAction != undefined) await props.loadAction();
        },
        () => {
          if (props.loadingStop) koruStore.commit(koruStoreTypes.mutations.loadingStop);
        }
      );
    });

    const deleteAction = async (item: Record<string, unknown>) => {
      koruStore.commit(koruStoreTypes.mutations.loadingStart);

      await tryOrToast(
        async () => {
          if (props.deleteAction != undefined) await props.deleteAction(item);

          const deleteIndex = valuesRef.value.indexOf(item, 0);
          if (deleteIndex > -1) {
            valuesRef.value.splice(deleteIndex, 1);
          }
        },
        "delete",
        t,
        () => koruStore.commit(koruStoreTypes.mutations.loadingStop)
      );
    };

    const createAction = () => {
      if (props.createPath) {
        koruStore.dispatch(koruStoreTypes.actions.navigateTo, props.createPath);
      } else {
        koruStore.dispatch(koruStoreTypes.actions.navigateTo, `${props.path}/new/edit`);
      }
    };

    const showDeleteItemDialog = ref(false);
    const deleteItem = ref({});

    const deleteItemAction = () => {
      showDeleteItemDialog.value = false;
      deleteAction(deleteItem.value);
    };

    const getRowCommands = (document: FirestoreDocument) => {
      const renderRowCommands: Record<string, unknown>[] = [];

      if (props.canView && (requiredModule == undefined || userCanRead(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.view",
          icon: "pi pi-eye",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(koruStoreTypes.actions.navigateTo, `${props.path}/${(object.reference as Record<string, unknown>).id}`);
          },
        });
      }

      if (props.canEdit && (requiredModule == undefined || userCanUpdate(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.edit",
          icon: "pi pi-pencil",
          command: (object: Record<string, unknown>) => {
            koruStore.dispatch(koruStoreTypes.actions.navigateTo, `${props.path}/${(object.reference as Record<string, unknown>).id}/edit`);
          },
        });
      }

      if (props.canDelete && (requiredModule == undefined || userCanDelete(requiredModule, document))) {
        renderRowCommands.push({
          label: "buttons.delete",
          icon: "pi pi-trash",
          command: (object: Record<string, unknown>) => {
            deleteItem.value = object.reference as Record<string, unknown>;
            showDeleteItemDialog.value = true;
          },
        });
      }

      if (props.rowCommands != undefined) {
        const rowCommandsLength = renderRowCommands.length;
        for (const rowCommand of props.rowCommands as unknown[]) {
          const rowCommandObject: RowCommandInterface = rowCommand as RowCommandInterface;
          if (rowCommandObject.condition(document) == true) {
            if (renderRowCommands.length == rowCommandsLength) {
              renderRowCommands.push({ label: "separator" });
            }
            renderRowCommands.push(rowCommand as Record<string, unknown>);
          }
        }
      }

      return renderRowCommands;
    };

    const onReorder = async (event: Record<string, unknown>) => {
      valuesRef.value = event.value as unknown[];
      if (props.onReorderAction != undefined) await props.onReorderAction(event.value as unknown[]);
    };

    return {
      createAction,
      deleteItemAction,
      deleteItem,
      getObjectValueWithField,
      getRowCommands,
      onReorder,
      requiredModule,
      showDeleteItemDialog,
      t,
      userCanCreate,
      userCanUpdate,
    };
  },
});
