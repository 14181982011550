import { LocaleModuleInterface } from "@/core/modules/locale/interfaces/LocaleModule.interface";

import { de } from "./de.ts";
import { en } from "./en.ts";
import { fr } from "./fr.ts";

export const statusLocaleModule: LocaleModuleInterface = {
  name: "status",
  messages: { de: de, en: en, fr: fr },
};
