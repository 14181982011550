export const de = {
  utility: {
    choose: "Wählen Sie ein Dienstprogramm aus",
    utility: "Dienstprogramm",
  },
  navigation: {
    utility: "Dienstprogramm",
  },
  roleModules: {
    utility: "Dienstprogramm",
  },
  toast: {
    error: {},
    success: {
      utilityDone: "Operation erfolgreich durchgeführt",
    },
    warning: {},
  },
};
