import { ActionTree } from "vuex";
import Papa from "papaparse";
import { read, utils } from "xlsx";

import { ExchangeStateInterface } from "../interfaces/ExchangeState.interface";
import { exchangeStoreTypes } from "./types";
import { Import } from "../objects/Import";
import { koruBatch } from "@/core/modules/batch";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

export const actions: ActionTree<ExchangeStateInterface, KoruStateInterface> = {
  async [exchangeStoreTypes.actions.importFromCsv](context, importData: Import): Promise<void> {
    try {
      const uploadedData: string | undefined = await context.getters[exchangeStoreTypes.getters.getUploadedData];
      if (uploadedData == undefined) throw new Error("noData");

      const data: Record<string, unknown> = Papa.parse(uploadedData);

      for (const row of data.data as string[][]) {
        importData.profile?.importRow(row, importData);
      }

      await koruBatch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
  async [exchangeStoreTypes.actions.importFromExcel](context, importData: Import): Promise<void> {
    try {
      const uploadedData: string | undefined = await context.getters[exchangeStoreTypes.getters.getUploadedData];
      if (uploadedData == undefined) throw new Error("noData");

      const wb = read(uploadedData, { type: "binary" });
      const wsName = wb.SheetNames[0];
      const ws = wb.Sheets[wsName];
      const excelData: string[][] = utils.sheet_to_json(ws, { header: 1 });

      for (const row of excelData) {
        importData.profile?.importRow(row, importData);
      }

      await koruBatch.commit();
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  },
};
