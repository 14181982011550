import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { Section } from "../objects/Section";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const sectionDummy: DummyModuleInterface = {
  collectionName: "sections",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const sectionsNames: string[] = ["ZRH ACC & OLLE", "GVA ACC & OLLW", "ZRH TWR/APP", "GVA TWR/APP", "REGIO airfields", "MIL units"];

    for (let i = 0; i < sectionsNames.length; i++) {
      const section = new Section();
      section.name = sectionsNames[i];
      section.order = i + 1;

      section.setTimestampFields("create");
      koruBatch.set(getCollectionReference("sections").doc(), section.toFirestore());
    }
  },
};
