import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { Status } from "../objects/Status";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const statusDummy: DummyModuleInterface = {
  collectionName: "statuses",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const statusesNames: string[] = ["ATCO", "Trainee", "Temporary Expert", "Retiree"];

    for (let i = 0; i < statusesNames.length; i++) {
      const status = new Status();
      status.name = statusesNames[i];
      status.order = i + 1;

      status.setTimestampFields("create");
      koruBatch.set(getCollectionReference("statuses").doc(), status.toFirestore());
    }
  },
};
