import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { koruBatch } from "@/core/modules/batch";
import { Member } from "../objects/Member";
import { memberModel } from "../models/Member.model";
import { SearchModuleInterface } from "@/core/modules/search/interfaces/SearchModule.interface";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const memberSearch: SearchModuleInterface = {
  collectionName: "members",
  format: (firestoreDocument: FirestoreDocument) => (firestoreDocument as Member).fullName as string,
  getLink: (firestoreDocument: FirestoreDocument) => `/members/${firestoreDocument.id}`,
  icon: "user",
  localeModule: "member",
  roleModule: "member",
  search: (text: string) => memberModel.searchDocuments(text),
  setSearchKeys: processSearchKeys,
  title: "member.member",
};

async function processSearchKeys(): Promise<void> {
  try {
    const members: Member[] = await memberModel.getDocuments();
    for (const member of members) {
      member.setSearchKeys();
      koruBatch.update(getCollectionReference("members").doc(member.id), { searchKeys: member.searchKeys });
    }

    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
