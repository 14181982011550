import { MutationTree } from "vuex";

import { WorkbenchStateInterface } from "../interfaces/WorkbenchState.interface";
import { workbenchStoreTypes } from "./types";

export const mutations: MutationTree<WorkbenchStateInterface> = {
  [workbenchStoreTypes.mutations.resetUploadedData]: (state: WorkbenchStateInterface): void => {
    state.uploadedData = undefined;
  },
  [workbenchStoreTypes.mutations.setUploadedData]: (state: WorkbenchStateInterface, data: string): void => {
    state.uploadedData = data;
  },
};
