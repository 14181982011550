import { KoruFile } from "@/core/modules/file/objects/KoruFile";
import { koruStorage } from "@/core/modules/storage";

export const deleteFile = async (emergencyDocFile: KoruFile): Promise<void> => {
  try {
    if (emergencyDocFile.uniqueName !== undefined) {
      await koruStorage.delete(emergencyDocFile.uniqueName);
    }
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
