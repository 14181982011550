import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

export class DateStrictField {
  public static fromFirestore(value: unknown, defaultValue: Date): Date {
    if (value === null || value === undefined) return defaultValue;

    return (value as firebase.firestore.Timestamp).toDate();
  }

  public static toFirestore(value: Date): firebase.firestore.Timestamp {
    return firebase.firestore.Timestamp.fromDate(value);
  }
}
