export class ObjectField {
  public static fromFirestore<T>(value: unknown, converter: (value: Record<string, unknown>) => T): T | undefined {
    if (value === null || value === undefined) return undefined;

    return converter(value as Record<string, unknown>);
  }

  public static toFirestore<T>(value: T | undefined, converter: (value: T) => Record<string, unknown>): Record<string, unknown> | null {
    if (value === null || value === undefined) return null;

    return converter(value);
  }
}
