import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedSectionInterface } from "../interfaces/LinkedSection.interface";
import { Section } from "./Section";

import { StringField } from "@/core/fields";

export class LinkedSection extends LinkedFirestoreDocument implements LinkedSectionInterface {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromSection(section: Section): LinkedSection {
    const linkedSection: LinkedSection = new LinkedSection();
    linkedSection.id = section.id;
    linkedSection.name = section.name;

    return linkedSection;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedSection {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
