export const de = {
  member: {
    addresses: "Kontaktdaten",
    approvedNote: "Mitglied genehmigt am {timestamp}",
    birthDate: "Geburtsdatum",
    birthDateError: "Geben Sie ein gültiges Geburtsdatum ein",
    country: "Land",
    email: "Primäre E-Mail-Adresse",
    emailError: "Geben Sie eine gültige E-Mail-Adresse ein",
    emailNote: "Mindestens eine E-Mail-Adresse sollte in der Domäne skyguide.ch sein",
    exportMembers: "Mitglieder exportieren",
    firstName: "Vorname",
    firstNameError: "Geben Sie einen gültigen Vornamen ein",
    fullName: "Vollständiger Name",
    gender: "Geschlecht",
    genderError: "Wählen Sie ein Geschlecht",
    genders: {
      female: "Weiblich",
      male: "Mann",
    },
    groups: "Gruppen",
    helveticaId: "HelvetiCA ID",
    info: "Information",
    lastName: "Nachname",
    lastNameError: "Geben Sie einen gültigen Nachnamen ein",
    mainUnit: "Hauptgerät",
    mainUnitError: "Wähle eine Haupteinheit aus",
    member: "Mitglied",
    members: "Mitglieder",
    miscData: "Zusätzliche Daten",
    notificationMode: "Benachrichtigungsmodus",
    notificationModes: {
      none: "Keine",
      push: "App-Push-Benachrichtigung",
      primaryEmail: "Primäre E-Mail",
      secondaryEmail: "Sekundäre E-Mail",
    },
    notes: "Anmerkungen",
    picture: "Bild",
    phone: "Telefon",
    phoneError: "Geben Sie eine gültige Telefonnummer ein",
    province: "Staat",
    secondaryEmail: "Sekundäre E-Mail-Adresse",
    secondaryEmailError: "Geben Sie eine gültige E-Mail-Adresse ein",
    section: "Abschnitt",
    skyguideEmployeeId: "Skyguide-Mitarbeiter-ID",
    skyguideEmployeeIdError: "Geben Sie eine gültige Mitarbeiter-ID von Skyguide ein",
    skyguideEntryDate: "Skyguide Eintrittsdatum",
    skyguideEntryDateError: "Geben Sie ein gültiges Eintrittsdatum ein",
    solidarityConsent: "Solidaritätseinwilligung",
    spokenLanguages: "Gesprochene Sprachen",
    status: "Zustand",
    statusError: "Status auswählen",
    street: "Adresse",
    town: "Stadt",
    units: "Einheiten",
    workData: "Arbeitsdaten",
    zipCode: "Postleitzahl",
  },
  navigation: {
    member: "Mitglieder",
  },
  roleModules: {
    member: "Mitglieder",
  },
  buttons: {
    showQrCode: "HelvetiCA QR anzeigen",
  },
};
