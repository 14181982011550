export const de = {
  candidate: {
    addresses: "Kontaktdaten",
    birthDate: "Geburtsdatum",
    birthDateError: "Geben Sie ein gültiges Geburtsdatum ein",
    candidate: "Kandidat",
    candidates: "Kandidaten",
    country: "Land",
    createdAt: "Eingetragen",
    email: "Primäre E-Mail-Adresse",
    emailError: "Geben Sie eine gültige E-Mail-Adresse ein",
    emailNote: "Mindestens eine E-Mail-Adresse sollte in der Domäne skyguide.ch sein",
    firstName: "Vorname",
    firstNameError: "Geben Sie einen gültigen Vornamen ein",
    fullName: "Vollständiger Name",
    gender: "Geschlecht",
    genderError: "Wählen Sie ein Geschlecht",
    genders: {
      female: "Weiblich",
      male: "Mann",
    },
    info: "Information",
    lastName: "Nachname",
    lastNameError: "Geben Sie einen gültigen Nachnamen ein",
    mainUnit: "Hauptgerät",
    mainUnitError: "Wähle eine Haupteinheit aus",
    miscData: "Zusätzliche Daten",
    phone: "Telefon",
    phoneError: "Geben Sie eine gültige Telefonnummer ein",
    province: "Staat",
    registeredNote: "Kandidat registriert am {timestamp}",
    secondaryEmail: "Sekundäre E-Mail-Adresse",
    secondaryEmailError: "Geben Sie eine gültige E-Mail-Adresse ein",
    section: "Abschnitt",
    skyguideEmployeeId: "Skyguide-Mitarbeiter-ID",
    skyguideEmployeeIdError: "Geben Sie eine gültige Mitarbeiter-ID von Skyguide ein",
    skyguideEntryDate: "Skyguide Eintrittsdatum",
    skyguideEntryDateError: "Geben Sie ein gültiges Eintrittsdatum ein",
    solidarityConsent: "Solidaritätseinwilligung",
    spokenLanguages: "Gesprochene Sprachen",
    status: "Zustand",
    statusError: "Status auswählen",
    street: "Adresse",
    town: "Stadt",
    workData: "Arbeitsdaten",
    zipCode: "Postleitzahl",
  },
  navigation: {
    candidate: "Kandidaten",
  },
  roleModules: {
    candidate: "Kandidaten",
  },
  buttons: {
    approveCandidate: "Kandidat genehmigen",
  },
  toast: {
    error: {
      approveCandidate: "Kandidat kann nicht genehmigt werden",
    },
    success: {
      approveCandidate: "Kandidat zugelassen",
    },
    warning: {},
  },
};
