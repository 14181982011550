import { ExchangeInterface } from "./interfaces/Exchange.interface";
import { ExchangeModuleInterface } from "./interfaces/ExchangeModule.interface";

const exchange: ExchangeInterface = {
  modules: {},
  getModule: function (moduleName: string): ExchangeModuleInterface {
    return this.modules[moduleName];
  },
};

export const koruExchange = exchange;

export const useKoruExchange = (): ExchangeInterface => {
  return koruExchange;
};
