export const fr = {
  group: {
    info: "Informations",
    group: "Groupe",
    groups: "Groupes",
    name: "Nom",
    nameError: "Entrez un nom valide",
  },
  navigation: {
    group: "Groupes",
  },
  roleModules: {
    group: "Groupes",
  },
  buttons: {
    removeFromGroup: "Supprimer du groupe",
  },
  toast: {
    error: {
      removeFromGroup: "Impossible de supprimer le membre du groupe",
      factoryGroup: "Le groupe est verrouillé",
    },
    success: {
      removeFromGroup: "Membre supprimé du groupe",
    },
    warning: {},
  },
};
