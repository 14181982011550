import { MutationTree } from "vuex";

import { EmergencyDocStateInterface } from "../interfaces/EmergencyDocState.interface";
import { emergencyDocStoreTypes } from "./types";

export const mutations: MutationTree<EmergencyDocStateInterface> = {
  [emergencyDocStoreTypes.mutations.uploadStart]: (state: EmergencyDocStateInterface): void => {
    state.isUploading = true;
    state.uploadProgressValue = 0;
  },
  [emergencyDocStoreTypes.mutations.uploadStop]: (state: EmergencyDocStateInterface): void => {
    state.isUploading = false;
    state.uploadProgressValue = 0;
  },
  [emergencyDocStoreTypes.mutations.setUploadProgressValue]: (state: EmergencyDocStateInterface, value: number): void => {
    state.uploadProgressValue = value;
  },
};
