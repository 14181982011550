export const fr = {
  workbench: {
    action: "Action",
    actionSelect: "Sélectionner une action",
    collections: "Collections",
    collectionsSelect: "Sélectionner une ou plusieurs collections",
    file: "Fichier",
    fileSelect: "Sélectionner un fichier à importer",
    go: "Aller",
    modules: {
      deleteData: "Supprimer une ou plusieurs collections",
      exportData: "Exporter une ou plusieurs collections",
      importData: "Importer des collections à partir d'une sauvegarde",
      sendTestMail: "Envoyer un e-mail de test",
      setSearchKeys: "Définir les clés de recherche",
    },
    types: {
      core: "Noyau",
      features: "Caractéristiques",
    },
    workbench: "Établi",
  },
  navigation: {
    workbench: "Établi",
  },
  roleModules: {
    workbench: "Établi",
  },
};
