export const en = {
  utility: {
    choose: "Choose a utility tool",
    utility: "Utility",
  },
  navigation: {
    utility: "Utility",
  },
  roleModules: {
    utility: "Utility",
  },
  toast: {
    error: {},
    success: {
      utilityDone: "Operation done successfully",
    },
    warning: {},
  },
};
