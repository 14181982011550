import { ActionTree } from "vuex";

import { EmergencyDocStateInterface } from "../interfaces/EmergencyDocState.interface";
import { emergencyDocStoreTypes } from "./types";
import { KoruFile } from "@/core/modules/file/objects/KoruFile";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

import { deleteFile, uploadFile } from "./actions/index";

export const actions: ActionTree<EmergencyDocStateInterface, KoruStateInterface> = {
  async [emergencyDocStoreTypes.actions.deleteFile](context, emergencyDocFile: KoruFile): Promise<void> {
    await deleteFile(emergencyDocFile);
  },
  async [emergencyDocStoreTypes.actions.uploadFile](context, file: File): Promise<KoruFile> {
    return await uploadFile(context, file);
  },
};
