export const en = {
  unit: {
    unit: "Unit",
    units: "Units",
    name: "Name",
    nameError: "Enter a valid name",
    section: "Section",
    sectionError: "Select a section",
  },
  navigation: {
    unit: "Units",
  },
  roleModules: {
    unit: "Units",
  },
};
