import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedStatusInterface } from "../interfaces/LinkedStatus.interface";
import { Status } from "./Status";

import { BooleanField, StringField } from "@/core/fields";

export class LinkedStatus extends LinkedFirestoreDocument implements LinkedStatusInterface {
  public name: string | undefined = undefined;
  public isSkyguideEmployee = true;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromStatus(status: Status): LinkedStatus {
    const linkedStatus: LinkedStatus = new LinkedStatus();
    linkedStatus.id = status.id;
    linkedStatus.name = status.name;
    linkedStatus.isSkyguideEmployee = status.isSkyguideEmployee;

    return linkedStatus;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedStatus {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.isSkyguideEmployee = BooleanField.fromFirestore(data.isSkyguideEmployee);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.isSkyguideEmployee = BooleanField.toFirestore(this.isSkyguideEmployee);

    return firestoreData;
  }
}
