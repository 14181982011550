import { RouteRecordRaw } from "vue-router";

export const statusRoutes: Array<RouteRecordRaw> = [
  {
    path: "/statuses/:id/edit",
    name: "StatusEdit",
    component: () => import("../views/StatusEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "status",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
