import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";
import { WorkbenchStateInterface } from "../interfaces/WorkbenchState.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

export const workbenchStore: KoruStoreModuleInterface<WorkbenchStateInterface> = createStoreModule(
  "workbenchState",
  initialState,
  getters,
  actions,
  mutations
);

export const useWorkbenchStore = (): KoruStoreModuleInterface<WorkbenchStateInterface> => {
  return workbenchStore;
};
