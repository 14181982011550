export const fr = {
  unit: {
    unit: "Unité",
    units: "Unités",
    name: "Nom",
    nameError: "Entrez un nom valide",
    section: "Section",
    sectionError: "Sélectionnez une rubrique",
  },
  navigation: {
    unit: "Unités",
  },
  roleModules: {
    unit: "Unités",
  },
};
