import { GetterTree } from "vuex";

import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { WorkbenchStateInterface } from "../interfaces/WorkbenchState.interface";
import { workbenchStoreTypes } from "./types";

export const getters: GetterTree<WorkbenchStateInterface, KoruStateInterface> = {
  [workbenchStoreTypes.getters.getUploadedData]: (state: WorkbenchStateInterface): string | undefined => {
    return state.uploadedData;
  },
};
