import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { FirestoreDocument } from "./FirestoreDocument";
import { FirestoreModelInterface } from "../interfaces/FirestoreModel.interface";
import { koruConfig } from "@/core/modules/config";
import { koruLog } from "@/core/modules/log";

import { runBeforeDeleteFunction } from "@/core/modules/helpers";
import { getCollectionReference } from "../helpers";

export class FirestoreModel<T extends FirestoreDocument> implements FirestoreModelInterface<T> {
  public collectionName: string;
  public parentCollectionName: string | undefined;
  public firestoreConverter: firebase.firestore.FirestoreDataConverter<T>;
  public syncCheck: boolean;
  public beforeDeleteFunction: string | undefined;

  /**
   * create the database module
   * @param collectionName name of the collection
   * @param parentCollectionName optional name of the parent collection
   */
  public constructor(type: new () => T, collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    this.collectionName = collectionName;
    this.parentCollectionName = parentCollectionName;
    this.syncCheck = syncCheck;
    this.beforeDeleteFunction = beforeDeleteFunction;
    this.firestoreConverter = this.createFirestoreConverter(type);
  }

  /**
   * get documents from the database
   * @param orderBy field to order by
   * @param orderDirection direction of the order
   * @param parentId optional id of the parent document
   * @returns array of documents
   */
  public async getDocuments(orderBy: string, orderDirection = "asc", parentId?: string): Promise<T[]> {
    try {
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

      const snapshot: firebase.firestore.QuerySnapshot<T> = await pathReference
        .withConverter(this.firestoreConverter)
        .orderBy(orderBy, orderDirection as firebase.firestore.OrderByDirection)
        .get();

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  /**
   * get a document from the database by id
   * @param firestoreDocumentId document id
   * @param parentId optional id of the parent document
   * @returns selected document, or throws an error if not found
   */
  public async getDocument(firestoreDocumentId: string, parentId?: string): Promise<T> {
    try {
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

      const doc: firebase.firestore.DocumentSnapshot<T> = await pathReference.withConverter(this.firestoreConverter).doc(firestoreDocumentId).get();

      if (doc.exists) {
        return doc.data() as T;
      } else {
        throw new Error(`#${firestoreDocumentId} not found in collection ${this.collectionName}`);
      }
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  /**
   * create a document in the database
   * @param firestoreDocument document to create
   * @param logAction whether to save the action in the log
   * @param parentId optional id of the parent document
   * @returns new document id
   */
  public async createDocument(firestoreDocument: T, logAction: boolean, parentId?: string): Promise<string> {
    try {
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

      firestoreDocument.setSearchKeys();
      firestoreDocument.setTimestampFields("create");

      const { id: newDocId } = await pathReference.withConverter(this.firestoreConverter).add(firestoreDocument);

      if (logAction) await koruLog.logInfo(`Firestore document #${newDocId} created in ${this.collectionName} collection`);

      return newDocId;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  public async updateDocument(firestoreDocument: T, logAction: boolean, parentId?: string): Promise<void> {
    try {
      if (this.syncCheck) {
        const oldFirestoreDocument: T = await this.getDocument(firestoreDocument.id);
        if (firestoreDocument.hasChangedFrom(oldFirestoreDocument)) {
          throw new Error("sync");
        }
      }

      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

      firestoreDocument.setSearchKeys();
      firestoreDocument.setTimestampFields("update");

      await pathReference.withConverter(this.firestoreConverter).doc(firestoreDocument.id).set(firestoreDocument);

      if (logAction) await koruLog.logInfo(`Firestore document #${firestoreDocument.id} updated in ${this.collectionName} collection`);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  /**
   * delete the document from the database
   * @param firestoreDocument document to delete
   * @param logAction whether to save the action in the log
   */
  public async deleteDocument(firestoreDocument: T, logAction: boolean, parentId?: string): Promise<void> {
    try {
      if (this.beforeDeleteFunction !== undefined) await runBeforeDeleteFunction(this.beforeDeleteFunction, firestoreDocument.id);

      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

      await pathReference.doc(firestoreDocument.id).delete();

      if (logAction) await koruLog.logInfo(`Firestore document #${firestoreDocument.id} deleted in ${this.collectionName} collection`);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  /**
   * search for documents in the database
   * @param searchText text to search for
   * @param orderBy field to order by
   * @param orderDirection direction of the order
   * @param parentId optional id of the parent document
   * @returns documents matching the search
   */
  public async searchDocuments(searchText: string, orderBy: string, orderDirection = "asc", parentId?: string): Promise<T[]> {
    try {
      const pathReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = this.getPathReference(parentId);

      const snapshot: firebase.firestore.QuerySnapshot<T> = await pathReference
        .withConverter(this.firestoreConverter)
        .where("searchKeys", "array-contains", searchText)
        .orderBy(orderBy, orderDirection as firebase.firestore.OrderByDirection)
        .limit(koruConfig.settings["searchResultLimit"] as number)
        .get();

      if (snapshot == undefined || snapshot.empty) return [];

      return snapshot.docs.map((doc) => doc.data());
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  /**
   * create the database path reference
   * @param parentId optional id of the parent document
   * @returns path reference
   */
  protected getPathReference(parentId?: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    let collectionPath: string = this.collectionName;
    if (this.parentCollectionName !== undefined && parentId !== undefined) {
      collectionPath = `${this.parentCollectionName}/${parentId}/${this.collectionName}`;
    }
    return getCollectionReference(collectionPath);
  }

  /**
   * create the firestore database converter
   * @param firestoreDocumentType firestore document type initializer
   * @returns firestore database converter
   */
  private createFirestoreConverter(firestoreDocumentType: { new (): T }): firebase.firestore.FirestoreDataConverter<T> {
    return {
      fromFirestore: function (
        snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
        options: firebase.firestore.SnapshotOptions | undefined
      ): T {
        const data = snapshot.data(options);
        const firestoreDocument = new firestoreDocumentType();
        firestoreDocument.fromFirestore(data, snapshot.id);
        return firestoreDocument;
      },
      toFirestore: function (firestoreDocument: T): Record<string, unknown> {
        return firestoreDocument.toFirestore();
      },
    };
  }
}
