export const it = {
  changelog: {
    addItem: "Aggiungi elemento",
    changelog: "Note di Rilascio",
    date: "Data",
    dateError: "Inserisci una data valida",
    empty: "Nessuna nota di rilascio da visualizzare",
    item: "Elemento",
    itemAdded: "Aggiunto",
    itemChanged: "Modificato",
    itemFixed: "Corretto",
    itemRemoved: "Rimosso",
    newItem: "Nuovo elemento",
    public: "Pubblico",
    release: "Rilascio",
    releasedOn: "Rilasciata",
    tabGeneral: "Generale",
    version: "Versione",
    versionError: "Inserisci la versione",
  },
  navigation: {
    changelog: "Note di rilascio",
  },
  roleModules: {
    changelog: "Note di rilascio",
  },
};
