export const de = {
  emergencyDoc: {
    emergencyDoc: "Notfalldokument",
    emergencyDocs: "Notfalldokumente",
    expiration: "Ablauf",
    files: "Dateien",
    noFiles: "Keine Dateien",
    title: "Titel",
    titleError: "Geben Sie einen gültigen Titel ein",
    uploadFile: "Datei hochladen",
  },
  navigation: {
    emergencyDoc: "Notfalldokumente",
  },
  roleModules: {
    emergencyDoc: "Notfalldokumente",
  },
};
