import { Member } from "../objects/Member";
import { MemberModelInterface } from "../interfaces/MemberModel.interface";
import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";

import { getMembersByGroup, getNextHelveticaNumber, removeMemberFromGroup } from "./methods";

export class MemberModel extends FirestoreModel<Member> implements MemberModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Member, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Member[]> {
    return super.getDocuments("fullName", "asc");
  }

  public async getDocument(memberId: string): Promise<Member> {
    return super.getDocument(memberId);
  }

  public async createDocument(member: Member, logAction = true): Promise<string> {
    member.setFullName();
    return super.createDocument(member, logAction);
  }

  public async updateDocument(member: Member, logAction = true): Promise<void> {
    member.setFullName();
    return super.updateDocument(member, logAction);
  }

  public async deleteDocument(member: Member, logAction = true): Promise<void> {
    return super.deleteDocument(member, logAction);
  }

  public async searchDocuments(searchText: string): Promise<Member[]> {
    return super.searchDocuments(searchText, "fullName", "asc");
  }

  /* custom methods */

  public async getMembersByGroup(groupId: string): Promise<Member[]> {
    return getMembersByGroup(groupId);
  }

  public async getNextHelveticaNumber(): Promise<number> {
    return getNextHelveticaNumber();
  }

  public async removeMemberFromGroup(member: Member, groupId: string): Promise<void> {
    return removeMemberFromGroup(member, groupId);
  }
}

export const memberModel: MemberModel = new MemberModel("members", undefined, true, "featuresMemberBeforeDelete");
