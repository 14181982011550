export const fr = {
  member: {
    adresses: "Coordonnées",
    approvedNote: "Membre approuvé le {timestamp}",
    birthDate: "Date de naissance",
    birthDateError: "Sélectionnez une date de naissance valide",
    country: "Pays",
    email: "Adresse e-mail principale",
    emailError: "Entrez une adresse e-mail valide",
    emailNote: "Au moins une adresse e-mail doit appartenir au domaine skyguide.ch",
    exportMembers: "Exporter des membres",
    firstName: "Prénom",
    firstNameError: "Entrez un prénom valide",
    fullName: "Nom complet",
    gender: "Sexe",
    genderError: "Sélectionnez un sexe",
    genders: {
      female: "Femelle",
      male: "Mâle",
    },
    groups: "Groupes",
    helveticaId: "HelvetiCA ID",
    info: "Informations",
    lastName: "Nom de famille",
    lastNameError: "Entrez un nom de famille valide",
    mainUnit: "Unité principale",
    mainUnitError: "Sélectionnez une unité principale",
    member: "Membre",
    members: "Membres",
    miscData: "Données supplémentaires",
    notificationMode: "Mode notification",
    notificationModes: {
      none: "Aucun",
      push: "Notification push de l'application",
      primaryEmail: "Adresse e-mail principale",
      secondaryEmail: "Adresse e-mail secondaire",
    },
    notes: "Remarques",
    picture: "Image",
    phone: "Téléphone",
    phoneError: "Entrez un numéro de téléphone valide",
    province: "État",
    secondaryEmail: "Adresse e-mail secondaire",
    secondaryEmailError: "Entrez une adresse e-mail valide",
    section: "Section",
    skyguideEmployeeId: "Identifiant d'employé Skyguide",
    skyguideEmployeeIdError: "Entrez un identifiant d'employé Skyguide valide",
    skyguideEntryDate: "Date d'entrée Skyguide",
    skyguideEntryDateError: "Entrez une date d'entrée valide",
    solidarityConsent: "Consentement solidaire",
    spokenLanguages: "Langues parlées",
    status: "État",
    statusError: "Sélectionnez un statut",
    street: "Adresse",
    town: "Ville",
    units: "Unités",
    workData: "Données de travail",
    zipCode: "Code postal",
  },
  navigation: {
    member: "Membres",
  },
  roleModules: {
    member: "Membres",
  },
  buttons: {
    showQrCode: "Afficher HelvetiCA QR",
  },
};
