import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedSection } from "@/features/modules/section/objects/LinkedSection";
import { LinkedUnitInterface } from "../interfaces/LinkedUnit.interface";
import { Unit } from "./Unit";

import { ObjectField, StringField } from "@/core/fields";

export class LinkedUnit extends LinkedFirestoreDocument implements LinkedUnitInterface {
  public name: string | undefined = undefined;
  public section: LinkedSection | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromUnit(unit: Unit): LinkedUnit {
    const linkedUnit: LinkedUnit = new LinkedUnit();
    linkedUnit.id = unit.id;
    linkedUnit.name = unit.name;
    linkedUnit.section = unit.section;

    return linkedUnit;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedUnit {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.section = ObjectField.fromFirestore<LinkedSection>(data.section, (value) => new LinkedSection(value));

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.section = ObjectField.toFirestore<LinkedSection>(this.section, (value) => value.toFirestore());

    return firestoreData;
  }
}
