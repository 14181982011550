import { Ref } from "vue";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { useAuthStore } from "@/core/modules/auth/store";
import { User } from "../objects/User";
import { userModel } from "../models/User.model";

export const toggleUserAction = async (user: User): Promise<void> => {
  try {
    user.blocked != user.blocked;

    await userModel.updateDocument(user, true);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};

/* validation */

export const uniqueEmailValidator = (oldEmail: Ref<string | undefined>) => {
  return async (value: string, record: User) => {
    try {
      if (record == undefined) return false;
      return userModel.isUserEmailUnique(value, record.id == "new" ? undefined : oldEmail.value);
    } catch (error: unknown) {
      console.log(error);
      return false;
    }
  };
};

export function userHasRoleByName(roleName: string): boolean {
  const authStore = useAuthStore();
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user == undefined) return false;
  if (user.role == undefined) return false;
  return user.role.name == roleName;
}

export function userCanRead(module: string, firestoreDocument?: FirestoreDocument): boolean {
  const authStore = useAuthStore();
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;
  if (
    user.role.readAll.indexOf(module) >= 0 ||
    (user.role.readOwn.indexOf(module) >= 0 && firestoreDocument && firestoreDocument.createdBy == user.id)
  ) {
    return true;
  }
  return false;
}

export function userCanCreate(module: string): boolean {
  const authStore = useAuthStore();
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;
  if (user.role.create.indexOf(module) >= 0) {
    return true;
  }
  return false;
}

export function userCanUpdate(module: string, firestoreDocument?: FirestoreDocument): boolean {
  const authStore = useAuthStore();
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;
  if (
    user.role.updateAll.indexOf(module) >= 0 ||
    (user.role.updateOwn.indexOf(module) >= 0 && firestoreDocument && firestoreDocument.createdBy == user.id)
  ) {
    return true;
  }
  return false;
}

export function userCanDelete(module: string, firestoreDocument?: FirestoreDocument): boolean {
  const authStore = useAuthStore();
  const user: User = authStore.getter(authStoreTypes.getters.getUser);
  if (user === undefined || user.role === undefined) return false;
  if (
    user.role.deleteAll.indexOf(module) >= 0 ||
    (user.role.deleteOwn.indexOf(module) >= 0 && firestoreDocument && firestoreDocument.createdBy == user.id)
  ) {
    return true;
  }
  return false;
}

export function userHasReadRight(user: User, right: string): boolean {
  if (right == "any" || (user.role?.readAll as Array<string>).includes(right) || (user.role?.readOwn as Array<string>).includes(right)) {
    return true;
  }
  return false;
}

export function userHasAnyReadRight(user: User, rights: string[]): boolean {
  if (rights.length == 1 && rights[0] == "any") return true;

  for (const right of rights) {
    if ((user.role?.readAll as Array<string>).includes(right) || (user.role?.readOwn as Array<string>).includes(right)) {
      return true;
    }
  }
  return false;
}
