import { Candidate } from "../objects/Candidate";
import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { Gender } from "@/features/modules/member/objects/Gender";
import { koruBatch } from "@/core/modules/batch";
import { LinkedStatus } from "@/features/modules/status/objects/LinkedStatus";
import { LinkedUnit } from "@/features/modules/unit/objects/LinkedUnit";
import { Status } from "@/features/modules/status/objects/Status";
import { statusModel } from "@/features/modules/status/models/Status.model";
import { Unit } from "@/features/modules/unit/objects/Unit";
import { unitModel } from "@/features/modules/unit/models/Unit.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

import { AddressField } from "@/core/fields";

export const candidateDummy: DummyModuleInterface = {
  collectionName: "candidates",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const statuses: Status[] = await statusModel.getDocuments();
    const units: Unit[] = await unitModel.getDocuments();

    const firstNames: string[] = ["Claire", "Sarah", "Jessica", "Jennifer", "Linda", "Elizabeth", "Mary", "Margaret", "Lisa", "Sandra"];
    const lastNames: string[] = ["Smith", "Johnson", "Brown", "Miller", "Davis", "Wilson", "Anderson", "Taylor", "Moore", "Scott"];

    for (let i = 0; i < 5; i++) {
      const candidate: Candidate = new Candidate();
      candidate.firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
      candidate.lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
      candidate.gender = Gender.Female;
      candidate.birthDate = new Date("1990-01-01");
      candidate.address = new AddressField();
      candidate.address.street = "123 Main Street";
      candidate.address.town = "New York";
      candidate.address.zipCode = "10001";
      candidate.address.province = "NY";
      candidate.address.country = "United States";
      candidate.phone = "123-456-7890";
      candidate.email = `${candidate.firstName.toLowerCase()}.${candidate.lastName.toLowerCase()}@spinningtop.it`;

      candidate.status = LinkedStatus.createFromStatus(statuses[Math.floor(Math.random() * statuses.length)]);
      if (candidate.status.isSkyguideEmployee) {
        candidate.secondaryEmail = `${candidate.firstName.toLowerCase()}.${candidate.lastName.toLowerCase()}@skyguide.ch`;

        candidate.mainUnit = LinkedUnit.createFromUnit(units[Math.floor(Math.random() * units.length)]);
        candidate.skyguideEmployeeId = "123456";
        candidate.skyguideEntryDate = new Date("2010-01-01");
        candidate.solidarityConsent = true;
      }

      candidate.spokenLanguages = ["de", "en"];

      candidate.setFullName();
      candidate.setTimestampFields("create");
      koruBatch.set(getCollectionReference("candidates").doc(), candidate.toFirestore());
    }
  },
};
