import firebase from "firebase/compat/app";
import "firebase/compat/functions";

import { firebaseFunctions } from "@/core/modules/firebase";

export async function runBeforeDeleteFunction(functionName: string, snapshotId: string): Promise<void> {
  const beforeDeleteFunction: firebase.functions.HttpsCallable = firebaseFunctions.httpsCallable(functionName);
  const functionResult: firebase.functions.HttpsCallableResult = await beforeDeleteFunction({ snapshotId: snapshotId });
  const response: { result: string; where?: string } = functionResult.data;

  if (response == undefined || response.result == undefined) throw new Error("actionError");
  if (response.result == "children") throw new Error("children");
  if (response.result != "ok") throw new Error("actionError");

  return Promise.resolve();
}
