import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedRoleInterface } from "../interfaces/LinkedRole.interface";
import { Role } from "./Role";

import { BooleanField, StringArrayField, StringField } from "@/core/fields";

export class LinkedRole extends LinkedFirestoreDocument implements LinkedRoleInterface {
  public name: string | undefined = undefined;
  public hasKoruAccess = true;
  public readAll: string[] = [];
  public readOwn: string[] = [];
  public create: string[] = [];
  public updateAll: string[] = [];
  public updateOwn: string[] = [];
  public deleteAll: string[] = [];
  public deleteOwn: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromRole(role: Role): LinkedRole {
    const linkedRole: LinkedRole = new LinkedRole();
    linkedRole.id = role.id;
    linkedRole.name = role.name;
    linkedRole.hasKoruAccess = role.hasKoruAccess;
    linkedRole.readAll = role.readAll;
    linkedRole.readOwn = role.readOwn;
    linkedRole.create = role.create;
    linkedRole.updateAll = role.updateAll;
    linkedRole.updateOwn = role.updateOwn;
    linkedRole.deleteAll = role.deleteAll;
    linkedRole.deleteOwn = role.deleteOwn;

    return linkedRole;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedRole {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);
    this.hasKoruAccess = BooleanField.fromFirestore(data.hasKoruAccess);
    this.readAll = StringArrayField.fromFirestore(data.readAll);
    this.readOwn = StringArrayField.fromFirestore(data.readOwn);
    this.create = StringArrayField.fromFirestore(data.create);
    this.updateAll = StringArrayField.fromFirestore(data.updateAll);
    this.updateOwn = StringArrayField.fromFirestore(data.updateOwn);
    this.deleteAll = StringArrayField.fromFirestore(data.deleteAll);
    this.deleteOwn = StringArrayField.fromFirestore(data.deleteOwn);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.hasKoruAccess = BooleanField.toFirestore(this.hasKoruAccess);
    firestoreData.readAll = StringArrayField.toFirestore(this.readAll);
    firestoreData.readOwn = StringArrayField.toFirestore(this.readOwn);
    firestoreData.create = StringArrayField.toFirestore(this.create);
    firestoreData.updateAll = StringArrayField.toFirestore(this.updateAll);
    firestoreData.updateOwn = StringArrayField.toFirestore(this.updateOwn);
    firestoreData.deleteAll = StringArrayField.toFirestore(this.deleteAll);
    firestoreData.deleteOwn = StringArrayField.toFirestore(this.deleteOwn);

    return firestoreData;
  }
}
