export class StringStrictField {
  public static fromFirestore(value: unknown, defaultValue: string): string {
    if (value === null || value === undefined) return defaultValue;

    return value as string;
  }

  public static toFirestore(value: string): string {
    return value;
  }
}
