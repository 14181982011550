export const en = {
  backup: {
    backup: "Backup",
    backups: "Backup",
    createdAt: "Created",
    createBackup: "Create Backup Now",
    createBackupDescription: "The backup is being created and will appear as soon as it is available",
    createBackupTitle: "Create Backup",
    type: "Type",
    types: {
      daily: "Daily",
      monthly: "Monthly",
      request: "On request",
      weekly: "Weekly",
    },
    user: "User",
  },
  navigation: {
    backup: "Backup",
  },
  roleModules: {
    backup: "Backup",
  },
};
