export const fr = {
  language: {
    flag: "Drapeau",
    language: "Langue",
    languages: "Langues",
    name: "Nom",
    nameError: "Entrez un nom valide",
    shortName: "Nom court",
    shortNameError: "Entrez un nom court valide",
  },
  navigation: {
    language: "Langues",
  },
  roleModules: {
    language: "Langues",
  },
};
