export const fr = {
  exchange: {
    addField: "Ajouter un champ",
    chooseFile: "Choisir un fichier",
    column: "Colonne",
    export: "Exporter les données",
    import: "Importer des données",
    profile: "Profil",
    profileError: "Sélectionnez un profil",
    sourceFile: "Fichier source",
    sourceFileError: "Sélectionner un fichier source",
    type: "Type",
    types: {
      dateIt: "Date (it)",
      dateEn: "Date (en)",
      email: "E-mail",
      text: "Texte",
    },
    value: "Valeur",
  },
  navigation: {
    exchange: "Échange de données",
  },
  roleModules: {
    exchange: "Échange de données",
  },
};
