import { MailMessage } from "@/core/modules/mail-message/objects/MailMessage";
import { mailMessageModel } from "@/core/modules/mail-message/models/MailMessage.model";
import { WorkbenchModuleInterface } from "../interfaces/WorkbenchModule.interface";
import { WorkbenchModuleType } from "../objects/WorkbenchModuleType";

export const sendTestMail: WorkbenchModuleInterface = {
  name: "sendTestMail",
  type: WorkbenchModuleType.Core,
  action: async (): Promise<void> => {
    return await performAction();
  },
};

async function performAction(): Promise<void> {
  try {
    const mailMessage: MailMessage = new MailMessage();
    mailMessage.recipientName = "Marco Lisatti";
    mailMessage.recipientEmail = "marco.lisatti@gmail.com";
    mailMessage.subject = "Messaggio di prova";
    mailMessage.message = "Ciao.\n\nQuesto è un messaggio di prova che dovrebbe essere recapitato.\n\nBuon lavoro!\n\nIl Team di KORU";

    await mailMessageModel.createDocument(mailMessage);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
