import { ExchangeModuleInterface } from "@/core/modules/exchange/interfaces/ExchangeModule.interface";
import { Field } from "@/core/modules/exchange/objects/Field";

export const memberExchange: ExchangeModuleInterface = {
  name: "memberExchange",
  collections: ["members"],
  availableColumns: [
    "lastName",
    "firstName",
    "town",
    "zipCode",
    "province",
    "phone",
    "fax",
    "communicationEmail",
    "invoiceEmail",
    "certifiedEmail",
    "vatCode",
    "fiscalCode",
    "recipientCode",
  ],
  defaultFields: [
    new Field("empty", undefined),
    new Field("name", "text"),
    new Field("town", "text"),
    new Field("vatCode", "text"),
    new Field("address", "text"),
    new Field("zipCode", "text"),
    new Field("recipientCode", "text"),
    new Field("certifiedEmail", "email"),
    new Field("province", "text"),
    new Field("fax", "text"),
  ],
  exportDataToCsv: () => {
    return;
  },
  exportDataToExcel: () => {
    return;
  },
  importRow: () => {
    return;
  },
};
