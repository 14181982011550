export const fr = {
  candidate: {
    adresses: "Coordonnées",
    birthDate: "Date de naissance",
    birthDateError: "Sélectionnez une date de naissance valide",
    candidate: "Candidat",
    candidates: "Candidats",
    country: "Pays",
    createdAt: "Enregistré",
    email: "Adresse e-mail principale",
    emailError: "Entrez une adresse e-mail valide",
    emailNote: "Au moins une adresse e-mail doit appartenir au domaine skyguide.ch",
    firstName: "Prénom",
    firstNameError: "Entrez un prénom valide",
    fullName: "Nom complet",
    gender: "Sexe",
    genderError: "Sélectionnez un sexe",
    genders: {
      female: "Femelle",
      male: "Mâle",
    },
    info: "Informations",
    lastName: "Nom de famille",
    lastNameError: "Entrez un nom de famille valide",
    mainUnit: "Unité principale",
    mainUnitError: "Sélectionnez une unité principale",
    miscData: "Données supplémentaires",
    phone: "Téléphone",
    phoneError: "Entrez un numéro de téléphone valide",
    province: "État",
    registeredNote: "Candidat inscrit le {timestamp}",
    secondaryEmail: "Adresse e-mail secondaire",
    secondaryEmailError: "Entrez une adresse e-mail valide",
    section: "Section",
    skyguideEmployeeId: "Identifiant d'employé Skyguide",
    skyguideEmployeeIdError: "Entrez un identifiant d'employé Skyguide valide",
    skyguideEntryDate: "Date d'entrée Skyguide",
    skyguideEntryDateError: "Entrez une date d'entrée valide",
    solidarityConsent: "Consentement solidaire",
    spokenLanguages: "Langues parlées",
    status: "État",
    statusError: "Sélectionnez un statut",
    street: "Adresse",
    town: "Ville",
    workData: "Données de travail",
    zipCode: "Code postal",
  },
  navigation: {
    candidate: "Candidats",
  },
  roleModules: {
    candidate: "Candidats",
  },
  buttons: {
    approveCandidate: "Approuver le candidat",
  },
  toast: {
    error: {
      approveCandidate: "Impossible d'approuver le candidat",
    },
    success: {
      approveCandidate: "Candidat approuvé",
    },
    warning: {},
  },
};
