import { ArrayField } from "../array-field/ArrayField";

export class StringArrayField {
  public static fromFirestore(value: unknown): string[] {
    return ArrayField.fromFirestore(value, (item: unknown) => item as string);
  }

  public static toFirestore(value: string[]): unknown[] {
    return ArrayField.toFirestore(value, (item) => item);
  }
}
