import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedSection } from "@/features/modules/section/objects/LinkedSection";
import { UnitInterface } from "../interfaces/Unit.interface";

import { NumberWithDefaultField, ObjectField, StringField } from "@/core/fields";

export class Unit extends FirestoreDocument implements UnitInterface {
  public name: string | undefined = undefined;
  public section: LinkedSection | undefined = undefined;
  public order = 999;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Unit {
    super.fromFirestore(data, id);

    this.name = StringField.fromFirestore(data.name);
    this.section = ObjectField.fromFirestore<LinkedSection>(data.section, (value) => new LinkedSection(value));
    this.order = NumberWithDefaultField.fromFirestore(data.order, 999);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.section = ObjectField.toFirestore<LinkedSection>(this.section, (value) => value.toFirestore());
    firestoreData.order = NumberWithDefaultField.toFirestore(this.order, 999);

    return firestoreData;
  }
}
