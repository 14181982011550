import { importBackup } from "@/core/plugins/import-export";

import { CollectionInterface } from "@/core/modules/config/interfaces/Collection.interface";
import { WorkbenchModuleInterface } from "../interfaces/WorkbenchModule.interface";
import { WorkbenchModuleType } from "../objects/WorkbenchModuleType";

export const importData: WorkbenchModuleInterface = {
  name: "importData",
  type: WorkbenchModuleType.Core,
  action: async (selectedCollections: CollectionInterface[], uploadedData: string | undefined): Promise<void> => {
    return await performAction(selectedCollections, uploadedData);
  },
};

async function performAction(selectedCollections: CollectionInterface[], uploadedData: string | undefined): Promise<void> {
  try {
    if (uploadedData == undefined) throw new Error("dataNotSet");

    const options = { autoParseDates: true, autoParseGeos: false, showLogs: true };
    await importBackup(JSON.parse(uploadedData), options);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
