export const emergencyDocStoreTypes = {
  getters: {
    getUploadProgressValue: "GET_UPLOAD_PROGRESS_VALUE",
    isLoading: "IS_LOADING",
    isUploading: "IS_UPLOADING",
  },
  actions: {
    deleteFile: "DELETE_FILE",
    uploadFile: "UPLOAD_FILE",
  },
  mutations: {
    setUploadProgressValue: "SET_UPLOAD_PROGRESS_VALUE",
    uploadStart: "UPLOAD_START",
    uploadStop: "UPLOAD_STOP",
  },
};
