import { LocaleModuleInterface } from "@/core/modules/locale/interfaces/LocaleModule.interface";

import { deCoreLabels } from "./de.ts";
import { enCoreLabels } from "./en.ts";
import { frCoreLabels } from "./fr.ts";
import { itCoreLabels } from "./it.ts";

export const coreLabels: LocaleModuleInterface = {
  name: "core",
  messages: { de: deCoreLabels, en: enCoreLabels, fr: frCoreLabels, it: itCoreLabels },
};
