import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { User } from "@/core/modules/user/objects/User";
import { userModel } from "../User.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const getUsersIdsByModuleAndRight = async (module: string, right: string): Promise<string[]> => {
  try {
    let snapshot: firebase.firestore.QuerySnapshot<User>;

    if (module == "any" || right == "any") {
      snapshot = await getCollectionReference(userModel.collectionName).withConverter(userModel.firestoreConverter).orderBy("fullName").get();
    } else {
      snapshot = await getCollectionReference(userModel.collectionName)
        .withConverter(userModel.firestoreConverter)
        .where(`role.${right}`, "array-contains", module)
        .orderBy("fullName")
        .get();
    }

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data().id);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
