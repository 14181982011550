export const de = {
  cloudEvent: {
    cloudEvent: "Wolkenereignis",
    cloudEvents: "Cloud-Ereignisse",
    createdAt: "Datum und Uhrzeit",
    details: "Details",
    function: "Funktion",
    noDetails: "Keine Details vorhanden",
    type: "Typ",
    types: {
      exception: "Ausnahme",
      report: "Bericht",
    },
  },
  buttons: {
    showDetails: "Details anzeigen",
  },
  navigation: {
    cloudEvent: "Cloud-Ereignisse",
  },
  roleModules: {
    cloudEvent: "Cloud-Ereignisse",
  },
};
