export const enCoreLabels = {
  auth: {
    askNewPassword: "Ask New Password",
    authenticationSubtitle: "Login with Your account",
    authenticationTitle: "Authentication",
    checkInbox: "Check Your e-mail inbox",
    choosePassword: "Choose a new password",
    email: "E-mail",
    emailBlank: "Insert the e-mail address",
    emailError: "The e-mail address is not valid",
    forgotError: "It was not possible to recover the password",
    forgotPasswordTitle: "Recover Password",
    forgotPasswordStart: "Forgot password?",
    forgotPasswordMiddle: "Ask",
    forgotPasswordEnd: "to recover now!",
    inputEmail: "Insert Your e-mail address",
    login: "Login",
    loginFailed: "Authentication failed",
    password: "Password",
    passwordBlank: "Insert the new password",
    passwordConfirm: "Confirm New Password",
    passwordConfirmBlank: "Insert the new password confirmation",
    passwordConfirmError: "Passwords don't match",
    passwordError: "Insert a valid password",
    passwordErrorChars: "The password should contain at least 8 characters",
    passwordErrorLowercase: "Insert at least one lowercase character",
    passwordErrorNumber: "Insert at least one number",
    passwordErrorUppercase: "Insert at least one uppercase character",
    passwordRequirementsTitle: "Requirements",
    passwordRequirementsChars: "At least 8 character",
    passwordRequirementsLowercase: "At least one lowercase character",
    passwordRequirementsNumber: "At least one number",
    passwordRequirementsUppercase: "At least one uppercase character",
    passwordRememberedStart: "Password remembered? Back to ",
    passwordRememberedEnd: "authentication",
    recoverPasswordTitle: "Recover Password",
    resetPassword: "Reset Password",
    resetPasswordError: "It was not possible to reset the password",
    resetPasswordSuccessStart: "The password has been reset. Go back to ",
    resetPasswordSuccessEnd: "authentication",
  },
  buttons: {
    back: "Back",
    cancel: "Cancel",
    clear: "Clear",
    close: "Close",
    delete: "Delete",
    download: "Download",
    disableUser: "Disable User",
    edit: "Edit",
    enableUser: "Enable User",
    export: "Export",
    import: "Import",
    load: "Load",
    markAsRead: "Mark as read",
    noAction: "No action available",
    print: "Print",
    run: "Run",
    save: "Save",
    search: "Search",
    send: "Send",
    sendNotification: "Send Notification",
    toggleUser: "Toggle State",
    upload: "Upload File",
    view: "View",
  },
  dialog: {
    confirmCloseWithoutSave: "Close without saving?",
    confirmDelete: "Are You sure to delete?",
    editingConfirm: "Unsaved data will be lost, are You sure?",
    editingTitle: "Exit withou save",
  },
  gen: {
    actions: "Actions",
    any: "Any",
    all: "All",
    backTo: "Back to",
    browse: "Browse",
    confirmActionText: "Confirm the execution of the selected action?",
    confirmActionTitle: "Confirm Action",
    dashboard: "Dashboard",
    deleteConfirm: "Are You sure to delete",
    deleteObject: "the selected object?",
    deleteTitle: "Delete Confirmation",
    developedby: "developed by",
    document: "Document",
    editPrefix: "Edit",
    empty: "Empty",
    errorContact: "An error occurred, contact the developer",
    expirationDaysLeft: "days left",
    expirationExpired: "Expired",
    expirationNotAvailable: "Not available",
    filter: "Filter",
    findAddress: "Find address",
    generals: "General",
    guest: "Guest",
    info: "Information",
    inputAddress: "Type an address",
    loading: "Loading in progress",
    logout: "Logout",
    maintenanceDescription: "Sorry for the inconvenience. Maintenance is underway, KORU will be back online soon.",
    maintenanceTitle: "We'll be back soon!",
    missingPrivileges: "You don't have the required permission to view the page",
    newPrefix: "New",
    no: "No",
    noData: "No data available",
    ok: "Ok",
    oops: "OOPS!",
    pageNotFound: "The requested page is not available",
    pageResults: "Results from [[first]] to [[last]] of [[totalRecords]] total records",
    profile: "Profile",
    reorder: "Order",
    search: "Search",
    unknown: "unknown",
    user: "User",
    viewPrefix: "View",
    yes: "Yes",
  },
  navigation: {
    about: "About",
    administration: "Administration",
    content: "Content",
    dashboard: "Dashboard",
    development: "Development",
    general: "General",
    structure: "Structure",
    tools: "Tools",
  },
  profile: {
    changeEmail: "Change E-mail",
    changePassword: "Change Password",
    changePasswordButton: "Change Password",
    email: "E-mail",
    oldPassword: "Old Password",
    oldPasswordBlank: "Insert the old password",
    newEmail: "New E-mail Address",
    newEmailError: "Insert a valid e-mail address",
    newPassword: "New Password",
    newPasswordBlank: "Insert the new password",
  },
  rights: {
    readAll: "Read All",
    readOwn: "Read Own",
    create: "Create",
    updateAll: "Edit All",
    updateOwn: "Edit Own",
    deleteAll: "Delete All",
    deleteOwn: "Delete Own",
  },
  roleModules: {},
  themes: {
    dim: "Night",
    light: "Light",
    solarized: "Solarized",
  },
  toast: {
    error: {
      actionError: "Unable to complete the action",
      backupDataNotSet: "Backup data not set",
      children: "Linked object still present",
      delete: "Unable to delete the element",
      deleteFile: "Unable to delete the file",
      emailChanged: "Unable to change the e-mail address",
      feedbackSent: "Unable to send the feedback",
      fiscalCode: "Unable to calculate the fiscal code",
      generic: "Unexpected error",
      noAction: "No action has been selected",
      notificationSent: "Unable to send the notification",
      notSupportedFileExtension: "Unsupported file extension",
      passwordChanged: "Unable to change the password",
      save: "Unable to save the object",
      search: "Unable to perform the search",
      sync: "Data has been edited by another user",
      title: "Error!",
      toggleUser: "Unable to save the state",
      uploadFile: "Unable to upload the file",
    },
    success: {
      actionSuccess: "Action completed successfully",
      backupDownloadSuccess: "Backup generated successfully",
      backupRestoreSuccess: "Backup restored successfully",
      delete: "Object deleted successfully",
      emailChanged: "E-mail address changed successfully",
      feedbackSent: "Feedback sent successfully",
      generic: "Operation completed successfully",
      notificationSent: "Notification sent successfully",
      passwordChanged: "Password changed successfully",
      save: "Object saved successfully",
      title: "Completed!",
      toggleUser: "State updated successfully",
      uploadFile: "File uploaded successfully",
    },
    warning: {
      dummyNoCollections: "Seleziona almeno una collezione",
      filterMissing: "Select all the filter fields",
      formInvalid: "Check the inserted fields",
      searchNoResults: "No results found",
      searchTextTooShort: "Insert at least 3 characters",
      title: "Warning!",
    },
  },
};
