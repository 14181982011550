export const it = {
  dummy: {
    collections: "Collezioni",
    collectionsSelect: "Seleziona una o più collezioni",
    deleteBefore: "Elimina dati esistenti",
    dummy: "Dummy Data",
    go: "Vai",
    subtitle: "Carica dati per testare l'applicazione",
  },
  navigation: {
    dummy: "Dummy Data",
  },
  roleModules: {
    dummy: "Dummy Data",
  },
  toast: {
    error: {
      uploadedDataNotFound: "Caricare i dati da importare",
    },
    success: {},
    warning: {},
  },
};
