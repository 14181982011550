import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { roleDummy } from "@/core/modules/role/dummy";
import { userDummy } from "@/core/modules/user/dummy";

export const install = async (): Promise<void> => {
  try {
    const installModules: DummyModuleInterface[] = [roleDummy, userDummy];

    for (const dummyModule of installModules) {
      await dummyModule.action();
      await koruBatch.commit();
    }

    console.log("Installed!");
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
