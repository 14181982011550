export const de = {
  language: {
    flag: "Flagge",
    language: "Sprache",
    languages: "Sprachen",
    name: "Name",
    nameError: "Geben Sie einen gültigen Namen ein",
    shortName: "Kurzname",
    shortNameError: "Geben Sie einen gültigen Kurznamen ein",
  },
  navigation: {
    language: "Sprachen",
  },
  roleModules: {
    language: "Sprachen",
  },
};
