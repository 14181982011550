import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { AuthInterface } from "../interfaces/Auth.interface";
import { authStoreTypes } from "../store/types";
import { firebaseAuth } from "@/core/modules/firebase";
import { koruBatch } from "@/core/modules/batch";
import { useAuthStore } from "../store";
import { User } from "@/core/modules/user/objects/User";
import { userModel } from "@/core/modules/user/models/User.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

import { DateField, StringField } from "@/core/fields";

export class AuthModel implements AuthInterface {
  async init(): Promise<void> {
    const authStore = useAuthStore();
    try {
      return new Promise<void>((resolve) => {
        firebaseAuth.onAuthStateChanged(async (user: firebase.User | null): Promise<void> => {
          if (user) {
            const dbUser: User = await userModel.getDocument(user.uid);
            authStore.commit(authStoreTypes.mutations.loginSuccess, dbUser);
            resolve();
          } else {
            authStore.commit(authStoreTypes.mutations.loginError);
            resolve();
          }
        });
      });
    } catch {
      authStore.commit(authStoreTypes.mutations.loadingStop);
      this.logout();
    }
  }

  async login(email: string, password: string): Promise<User> {
    try {
      const userCredential: firebase.auth.UserCredential = await firebaseAuth.signInWithEmailAndPassword(email, password);
      if (userCredential.user !== null) {
        const user: User = await userModel.getDocument(userCredential.user.uid);
        if (user.role === undefined || user.role.hasKoruAccess === false) {
          await firebaseAuth.signOut();
          throw new Error("User doesn't have access to Koru");
        }
        return user;
      }
      throw new Error("User credentials are null");
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async logout(): Promise<void> {
    try {
      await firebaseAuth.signOut();
    } catch {
      throw new Error("Unable to logout");
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      await firebaseAuth.sendPasswordResetEmail(email);
    } catch {
      throw new Error("Unable to send password reset email");
    }
  }

  async recoverPassword(code: string, newPassword: string): Promise<void> {
    try {
      await firebaseAuth.confirmPasswordReset(code, newPassword);
    } catch {
      throw new Error("Unable to recover password");
    }
  }

  async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      if (!firebaseAuth.currentUser || !firebaseAuth.currentUser.email) {
        throw new Error("Invalid data");
      }

      const credentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(firebaseAuth.currentUser.email, oldPassword);
      await firebaseAuth.currentUser.reauthenticateWithCredential(credentials);

      if (firebaseAuth.currentUser == null) {
        throw new Error("Unable to reauthenticate");
      }

      await firebaseAuth.currentUser.updatePassword(newPassword);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  async updateEmail(newEmail: string, password: string): Promise<void> {
    const authStore = useAuthStore();

    try {
      if (!firebaseAuth.currentUser || !firebaseAuth.currentUser.email) {
        throw new Error("Invalid data");
      }

      const credentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(firebaseAuth.currentUser.email, password);
      await firebaseAuth.currentUser.reauthenticateWithCredential(credentials);

      if (firebaseAuth.currentUser == null) {
        throw new Error("Unable to reauthenticate");
      }

      await firebaseAuth.currentUser.updateEmail(newEmail);

      const user: User = authStore.getter(authStoreTypes.getters.getUser);

      koruBatch.update(getCollectionReference("users").doc(user.id), {
        email: StringField.toFirestore(newEmail),
        updatedAt: DateField.toFirestore(new Date()),
        updatedBy: StringField.toFirestore(user.id),
      });
      koruBatch.commit();

      const newCredentials: firebase.auth.AuthCredential = firebase.auth.EmailAuthProvider.credential(newEmail, password);
      await firebaseAuth.currentUser.reauthenticateWithCredential(newCredentials);
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }

  isLoggedIn(): boolean {
    return firebaseAuth.currentUser ? true : false;
  }
}

export const authModel: AuthModel = new AuthModel();
