import { EmergencyDocStateInterface } from "../interfaces/EmergencyDocState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

export const emergencyDocStore: KoruStoreModuleInterface<EmergencyDocStateInterface> = createStoreModule(
  "emergencyDocState",
  initialState,
  getters,
  actions,
  mutations
);

export const useEmergencyDocStore = (): KoruStoreModuleInterface<EmergencyDocStateInterface> => {
  return emergencyDocStore;
};
