export const en = {
  group: {
    info: "Information",
    group: "Group",
    groups: "Groups",
    name: "Name",
    nameError: "Enter a valid name",
  },
  navigation: {
    group: "Groups",
  },
  roleModules: {
    group: "Groups",
  },
  buttons: {
    removeFromGroup: "Remove from group",
  },
  toast: {
    error: {
      removeFromGroup: "Unable to remove member from group",
      factoryGroup: "Group is locked",
    },
    success: {
      removeFromGroup: "Member removed from group",
    },
    warning: {},
  },
};
