export class BooleanField {
  public static fromFirestore(value: unknown): boolean {
    if (value === null || value === undefined) return false;

    return value as boolean;
  }

  public static toFirestore(value: boolean): boolean {
    return value;
  }
}
