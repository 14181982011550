import { ActionTree } from "vuex";

import { EmptyStateInterface } from "@/core/modules/store/interfaces/EmptyState.interface";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { SearchResultInterface } from "../interfaces/SearchResult.interface";
import { searchStoreTypes } from "./types";

import { performSearch } from "./actions/index";

export const actions: ActionTree<EmptyStateInterface, KoruStateInterface> = {
  // perform search
  async [searchStoreTypes.actions.performSearch](context, payload: Record<string, unknown>): Promise<SearchResultInterface[]> {
    return await performSearch(payload);
  },
};
