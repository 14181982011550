
import { computed, defineComponent, onBeforeUnmount, onMounted, Ref, ref } from "vue";

import { authStoreTypes } from "@/core/modules/auth/store/types";
import { Notification } from "../objects/Notification";
import { notificationModel } from "../models/Notification.model";
import { NotificationTypeInterface } from "../interfaces/NotificationTypeInterface";
import { useKoruConfig } from "@/core/modules/config";
import { useKoruLocale } from "@/core/modules/locale";
import { useAuthStore } from "@/core/modules/auth/store";
import { User } from "@/core/modules/user/objects/User";

import { getNotificationsMatrix } from "../helpers";

export default defineComponent({
  name: "NotificationSelector",
  props: {
    topbarNotificationMenuActive: Boolean,
  },
  emits: ["topbar-notification"],
  setup() {
    const { t } = useKoruLocale();
    const koruConfig = useKoruConfig();
    const authStore = useAuthStore();
    const notificationTypes: Record<string, NotificationTypeInterface> = koruConfig.notification.types;

    const notifications: Ref<Notification[]> = ref([]);
    const notificationsMatrix: Ref<Record<string, number>> = ref({});

    const notificationsCount = computed(() => {
      return notifications.value.length;
    });

    let notificationTimer: number;

    onMounted(() => {
      startNotificationTimer();
    });

    onBeforeUnmount(() => {
      stopNotificationTimer();
    });

    const startNotificationTimer = () => {
      checkNotifications();
      notificationTimer = window.setInterval(checkNotifications, koruConfig.notification.interval);
    };

    const stopNotificationTimer = () => {
      if (notificationTimer) {
        clearInterval(notificationTimer);
      }
    };

    const checkNotifications = async () => {
      const user: User = authStore.getter(authStoreTypes.getters.getUser);
      notifications.value = await notificationModel.getUnreadNotifications(user.id);
      notificationsMatrix.value = getNotificationsMatrix(notifications.value);
    };

    return {
      notificationsMatrix,
      notificationsCount,
      notificationTypes,
      t,
    };
  },
  methods: {
    onTopbarNotificationMenuButtonClick(event: Event): void {
      this.$emit("topbar-notification", event);
    },
  },
});
