import { koruConfig } from "@/core/modules/config";
import { UserDetailInterface } from "../interfaces/UserDetail.interface";

import { StringStrictField } from "@/core/fields";

export class UserDetail implements UserDetailInterface {
  public locale: string = koruConfig.locale.default;
  public theme: string = koruConfig.layout.defaultTheme;

  public constructor(firestoreData?: Record<string, unknown>) {
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public fromFirestore(data: Record<string, unknown>): UserDetail {
    this.locale = StringStrictField.fromFirestore(data.locale, koruConfig.locale.default);
    this.theme = StringStrictField.fromFirestore(data.theme, koruConfig.layout.defaultTheme);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = {};

    firestoreData.locale = StringStrictField.toFirestore(this.locale);
    firestoreData.theme = StringStrictField.toFirestore(this.theme);

    return firestoreData;
  }
}
