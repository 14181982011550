import { EventBusInterface } from "./interfaces/EventBus.inteface";

export default function EventBus(): EventBusInterface {
  const allHandlers = new Map();

  return {
    on<T>(type: string, handler: (params: T) => void) {
      let handlers = allHandlers.get(type);
      if (!handlers) {
        handlers = [handler];
      } else {
        handlers.push(handler);
      }
      allHandlers.set(type, handlers);
    },

    off<T>(type: string, handler: (params: T) => void) {
      const handlers = allHandlers.get(type);
      if (handlers) {
        handlers.splice(handlers.indexOf(handler) >>> 0, 1);
      }
    },

    emit<T>(type: string, evt: T) {
      const handlers = allHandlers.get(type);
      if (handlers) {
        handlers.slice().map((handler: (params: T) => void) => {
          handler(evt);
        });
      }
    },

    has(type: string): boolean {
      const handlers = allHandlers.get(type);
      if (!handlers) {
        return false;
      }
      return true;
    },
  };
}
