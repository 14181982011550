export const it = {
  backup: {
    backup: "Backup",
    backups: "Backup",
    createdAt: "Creazione",
    createBackup: "Crea Backup Ora",
    createBackupDescription: "Il backup è in fase di creazione e apparirà nella lista non appena sarà completato",
    createBackupTitle: "Creazione Backup",
    type: "Tipo",
    types: {
      daily: "Giornaliero",
      monthly: "Mensile",
      request: "Su richiesta",
      weekly: "Settimanale",
    },
    user: "Utente",
  },
  navigation: {
    backup: "Backup",
  },
  roleModules: {
    backup: "Backup",
  },
};
