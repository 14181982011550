import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { Role } from "../objects/Role";
import { RoleModelInterface } from "../interfaces/RoleModel.interface";

export class RoleModel extends FirestoreModel<Role> implements RoleModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Role, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Role[]> {
    return super.getDocuments("name", "asc");
  }

  public async getDocument(roleId: string): Promise<Role> {
    return super.getDocument(roleId);
  }

  public async createDocument(role: Role, logAction = true): Promise<string> {
    return super.createDocument(role, logAction);
  }

  public async updateDocument(role: Role, logAction = true): Promise<void> {
    return super.updateDocument(role, logAction);
  }

  public async deleteDocument(role: Role, logAction = true): Promise<void> {
    return super.deleteDocument(role, logAction);
  }

  public async searchDocuments(searchText: string): Promise<Role[]> {
    return super.searchDocuments(searchText, "name", "asc");
  }
}

export const roleModel: RoleModel = new RoleModel("roles", undefined, true, "coreRoleBeforeDelete");
