export const en = {
  section: {
    section: "Section",
    sections: "Sections",
    name: "Name",
    nameError: "Enter a valid name",
  },
  navigation: {
    section: "Sections",
  },
  roleModules: {
    section: "Sections",
  },
};
