export const de = {
  exchange: {
    addField: "Feld hinzufügen",
    chooseFile: "Datei auswählen",
    column: "Spalte",
    export: "Daten exportieren",
    import: "Daten importieren",
    profile: "Profil",
    profileError: "Profil auswählen",
    sourceFile: "Quelldatei",
    sourceFileError: "Quelldatei auswählen",
    type: "Typ",
    types: {
      dateIt: "Datum (it)",
      dateEn: "Datum (en)",
      email: "E-Mail",
      text: "Text",
    },
    value: "Wert",
  },
  navigation: {
    exchange: "Datenaustausch",
  },
  roleModules: {
    exchange: "Datenaustausch",
  },
};
