export const fr = {
  dummy: {
    collections: "Collections",
    collectionsSelect: "Sélectionner une ou plusieurs collections",
    deleteBefore: "Supprimer les données existantes",
    dummy: "Dummy Data",
    go: "Aller",
    subtitle: "Charger les données pour tester l'application",
  },
  navigation: {
    dummy: "Dummy Data",
  },
  roleModules: {
    dummy: "Dummy Data",
  },
  toast: {
    error: {
      uploadedDataNotFound: "Télécharger les données à importer",
    },
    success: {},
    warning: {},
  },
};
