import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { koruBatch } from "@/core/modules/batch";
import { Member } from "@/features/modules/member/objects/Member";
import { memberModel } from "@/features/modules/member/models/Member.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const removeMemberFromGroup = async (member: Member, groupId: string): Promise<void> => {
  try {
    koruBatch.update(getCollectionReference(memberModel.collectionName).doc(member.id), {
      [`groups.${groupId}`]: firebase.firestore.FieldValue.delete(),
    });

    return koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
