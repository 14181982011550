export const it = {
  cloudEvent: {
    cloudEvent: "Evento cloud",
    cloudEvents: "Eventi cloud",
    createdAt: "Data e ora",
    details: "Dettagli",
    function: "Funzione",
    noDetails: "Nessun dettaglio presente",
    type: "Tipo",
    types: {
      exception: "Eccezione",
      report: "Resoconto",
    },
  },
  buttons: {
    showDetails: "Mostra dettagli",
  },
  navigation: {
    cloudEvent: "Eventi cloud",
  },
  roleModules: {
    cloudEvent: "Eventi cloud",
  },
};
