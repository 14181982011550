import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedUser } from "@/core/modules/user/objects/LinkedUser";
import { LogInterface } from "../interfaces/Log.interface";

import { ObjectField, StringField } from "@/core/fields";

export class Log extends FirestoreDocument implements LogInterface {
  public user: LinkedUser | undefined = undefined;
  public type: string | undefined = undefined;
  public message: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Log {
    super.fromFirestore(data, id);

    this.user = ObjectField.fromFirestore<LinkedUser>(data.user, (value: Record<string, unknown>) => new LinkedUser(value));
    this.type = StringField.fromFirestore(data.type);
    this.message = StringField.fromFirestore(data.message);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.user = ObjectField.toFirestore<LinkedUser>(this.user, (value: LinkedUser) => value.toFirestore());
    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.message = StringField.toFirestore(this.message);

    return firestoreData;
  }
}
