import { ConfigInterface } from "./interfaces/Config.interface";

import configDev from "@/data/app-config-dev.json";
import configProd from "@/data/app-config-prod.json";

let env = "development";
if (process.env && process.env.VUE_APP_ENVIRONMENT) {
  env = process.env.VUE_APP_ENVIRONMENT.trim().toLowerCase();
}

export const environment: string = env;

const configsMap: Map<string, ConfigInterface> = new Map<string, ConfigInterface>([
  ["development", configDev],
  ["production", configProd],
]);

if (!configsMap.has(env)) {
  throw Error(`Could not find config for VUE_APP_ENVIRONMENT key "${env}"`);
}

export const koruConfig: ConfigInterface = configsMap.get(env) as ConfigInterface;

export const useKoruConfig = (): ConfigInterface => {
  return koruConfig;
};
