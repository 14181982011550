export const de = {
  stat: {
    choose: "Wählen Sie eine anzuzeigende Statistik",
    stat: "Statistiken",
  },
  navigation: {
    stat: "Statistiken",
  },
  roleModules: {
    stat: "Statistiken",
  },
};
