import { GetterTree } from "vuex";
import { addHours, compareAsc } from "date-fns";

import { AuthStateInterface } from "../interfaces/AuthState.interface";
import { authStoreTypes } from "./types";
import { koruBatch } from "@/core/modules/batch";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { User } from "@/core/modules/user/objects/User";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

import { DateField } from "@/core/fields";

export const getters: GetterTree<AuthStateInterface, KoruStateInterface> = {
  [authStoreTypes.getters.isLoggedIn]: (state: AuthStateInterface): boolean => {
    return state.isLoggedIn;
  },
  [authStoreTypes.getters.getUser]: (state: AuthStateInterface): User | undefined => {
    const user: User | undefined = state.user;

    if (user !== undefined) {
      if (user.lastSeen === undefined || compareAsc(new Date(), addHours(user.lastSeen, 1)) > 0) {
        koruBatch.update(getCollectionReference("users").doc(user.id), { lastSeen: DateField.toFirestore(new Date()) });
        koruBatch.commit();
        user.lastSeen = new Date();
      }
    }

    return user;
  },
  [authStoreTypes.getters.isLoading]: (state: AuthStateInterface): boolean => {
    return state.loading;
  },
};
