import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { Status } from "../objects/Status";
import { StatusModelInterface } from "../interfaces/StatusModel.interface";

import { countStatuses, reorderStatuses } from "./methods";

export class StatusModel extends FirestoreModel<Status> implements StatusModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Status, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Status[]> {
    return super.getDocuments("order", "asc");
  }

  public async getDocument(statusId: string): Promise<Status> {
    return super.getDocument(statusId);
  }

  public async createDocument(status: Status, logAction = true): Promise<string> {
    return super.createDocument(status, logAction);
  }

  public async updateDocument(status: Status, logAction = true): Promise<void> {
    return super.updateDocument(status, logAction);
  }

  public async deleteDocument(status: Status, logAction = true): Promise<void> {
    return super.deleteDocument(status, logAction);
  }

  public async searchDocuments(): Promise<Status[]> {
    return Promise.resolve([]);
  }

  /* custom methods */

  public async countStatuses(): Promise<number> {
    return countStatuses();
  }

  public async reorderStatuses(statuses: Status[]): Promise<void> {
    return reorderStatuses(statuses);
  }
}

export const statusModel: StatusModel = new StatusModel("statuses", undefined, true, "featuresStatusBeforeDelete");
