import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { CollectionInterface } from "@/core/modules/config/interfaces/Collection.interface";
import { koruBatch } from "@/core/modules/batch";
import { WorkbenchModuleInterface } from "../interfaces/WorkbenchModule.interface";
import { WorkbenchModuleType } from "../objects/WorkbenchModuleType";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const deleteData: WorkbenchModuleInterface = {
  name: "deleteData",
  type: WorkbenchModuleType.Core,
  action: async (selectedCollections: CollectionInterface[]): Promise<void> => {
    return await performAction(selectedCollections);
  },
};

async function performAction(selectedCollections: CollectionInterface[]): Promise<void> {
  try {
    for (const collection of selectedCollections) {
      const snapshot: firebase.firestore.QuerySnapshot = await getCollectionReference(collection.name).get();
      if (!snapshot.empty) {
        for (const doc of snapshot.docs) koruBatch.delete(doc.ref);
      }
    }
    await koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
}
