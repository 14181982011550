import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Member } from "@/features/modules/member/objects/Member";
import { memberModel } from "../Member.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const getNextHelveticaNumber = async (): Promise<number> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<Member> = await getCollectionReference(memberModel.collectionName)
      .withConverter(memberModel.firestoreConverter)
      .orderBy("helveticaIdNumber", "desc")
      .limit(1)
      .get();

    if (snapshot != undefined && snapshot.empty === false) return snapshot.docs[0].data().helveticaIdNumber + 1;

    return 1;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
