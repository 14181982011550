import { registerLocaleModule } from "@/core/modules/locale/helpers";

import { candidateLocaleModule } from "@/features/modules/candidate/locales";
import { configurationLocaleModule } from "@/features/modules/configuration/locales";
import { emergencyDocLocaleModule } from "@/features/modules/emergency-doc/locales";
import { groupLocaleModule } from "@/features/modules/group/locales";
import { languageLocaleModule } from "@/features/modules/language/locales";
import { memberLocaleModule } from "@/features/modules/member/locales";
import { sectionLocaleModule } from "@/features/modules/section/locales";
import { statusLocaleModule } from "@/features/modules/status/locales";
import { unitLocaleModule } from "@/features/modules/unit/locales";

export const registerFeaturesLocales = () => {
  registerLocaleModule(candidateLocaleModule);
  registerLocaleModule(configurationLocaleModule);
  registerLocaleModule(emergencyDocLocaleModule);
  registerLocaleModule(groupLocaleModule);
  registerLocaleModule(languageLocaleModule);
  registerLocaleModule(memberLocaleModule);
  registerLocaleModule(sectionLocaleModule);
  registerLocaleModule(statusLocaleModule);
  registerLocaleModule(unitLocaleModule);
};
