import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { Gender } from "../objects/Gender";
import { Group } from "@/features/modules/group/objects/Group";
import { groupModel } from "@/features/modules/group/models/Group.model";
import { koruBatch } from "@/core/modules/batch";
import { LinkedGroup } from "@/features/modules/group/objects/LinkedGroup";
import { LinkedStatus } from "@/features/modules/status/objects/LinkedStatus";
import { LinkedUnit } from "@/features/modules/unit/objects/LinkedUnit";
import { Member } from "../objects/Member";
import { NotificationMode } from "../objects/NotificationMode";
import { Status } from "@/features/modules/status/objects/Status";
import { statusModel } from "@/features/modules/status/models/Status.model";
import { Unit } from "@/features/modules/unit/objects/Unit";
import { unitModel } from "@/features/modules/unit/models/Unit.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

import { AddressField } from "@/core/fields";

export const memberDummy: DummyModuleInterface = {
  collectionName: "members",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const groups: Group[] = await groupModel.getDocuments();
    const statuses: Status[] = await statusModel.getDocuments();
    const units: Unit[] = await unitModel.getDocuments();

    const firstNames: string[] = ["Aaron", "Michael", "John", "Peter", "Jack", "William", "Benjamin", "Ivo", "Jesse", "Anthony"];
    const lastNames: string[] = ["Smith", "Johnson", "Brown", "Miller", "Davis", "Wilson", "Anderson", "Taylor", "Moore", "Scott"];

    for (let i = 1; i <= 12; i++) {
      const member: Member = new Member();
      member.firstName = firstNames[Math.floor(Math.random() * firstNames.length)];
      member.lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
      member.gender = Gender.Male;
      member.birthDate = new Date("1990-01-01");
      member.address = new AddressField();
      member.address.street = "123 Main Street";
      member.address.town = "New York";
      member.address.zipCode = "10001";
      member.address.province = "NY";
      member.address.country = "United States";
      member.phone = "123-456-7890";
      member.email = `${member.firstName.toLowerCase()}.${member.lastName.toLowerCase()}.${i}@spinningtop.it`;
      member.notificationMode = NotificationMode.None;

      member.status = LinkedStatus.createFromStatus(statuses[Math.floor(Math.random() * statuses.length)]);
      if (member.status.isSkyguideEmployee) {
        member.secondaryEmail = `${member.firstName.toLowerCase()}.${member.lastName.toLowerCase()}.${i}@skyguide.ch`;

        const primaryUnit: LinkedUnit = LinkedUnit.createFromUnit(units[Math.floor(Math.random() * units.length)]);
        const secondaryUnit: LinkedUnit = LinkedUnit.createFromUnit(units[Math.floor(Math.random() * units.length)]);
        member.addLinkedUnit(primaryUnit);
        if (primaryUnit.id !== secondaryUnit.id) member.addLinkedUnit(secondaryUnit);

        member.mainUnit = primaryUnit;
        member.skyguideEmployeeId = "123456";
        member.skyguideEntryDate = new Date("2010-01-01");
        member.solidarityConsent = true;
      }

      member.spokenLanguages = ["de", "en"];

      const primaryGroup: LinkedGroup = LinkedGroup.createFromGroup(groups[Math.floor(Math.random() * groups.length)]);
      const secondaryGroup: LinkedGroup = LinkedGroup.createFromGroup(groups[Math.floor(Math.random() * groups.length)]);
      member.addLinkedGroup(primaryGroup);
      if (primaryGroup.id !== secondaryGroup.id) member.addLinkedGroup(secondaryGroup);

      member.helveticaIdNumber = i;
      member.setHelveticaId();
      member.setFullName();
      member.setSearchKeys();
      member.setTimestampFields("create");
      koruBatch.set(getCollectionReference("members").doc(), member.toFirestore());
    }
  },
};
