export const exchangeStoreTypes = {
  getters: {
    getUploadedData: "GET_UPLOADED_DATA",
  },
  actions: {
    importFromCsv: "IMPORT_FROM_CSV",
    importFromExcel: "IMPORT_FROM_EXCEL",
  },
  mutations: {
    resetUploadedData: "RESET_UPLOADED_DATA",
    setUploadedData: "SET_UPLOADED_DATA",
  },
};
