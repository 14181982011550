import { ActionTree } from "vuex";

import { CollectionInterface } from "@/core/modules/config/interfaces/Collection.interface";
import { koruWorkbench } from "../module";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { WorkbenchStateInterface } from "../interfaces/WorkbenchState.interface";
import { workbenchStoreTypes } from "./types";

export const actions: ActionTree<WorkbenchStateInterface, KoruStateInterface> = {
  // perform action
  async [workbenchStoreTypes.actions.performAction](context, payload: Record<string, unknown>): Promise<void> {
    const actionName: string = payload.actionName as string;
    const selectedCollections: CollectionInterface[] = payload.selectedCollections as CollectionInterface[];
    const uploadedData: string | undefined = await context.getters[workbenchStoreTypes.getters.getUploadedData];

    if (actionName == undefined || selectedCollections == undefined) throw new Error("invalidParameters");

    try {
      for (const workbenchModule of koruWorkbench.modules) {
        if (workbenchModule.name == actionName) {
          await workbenchModule.action(selectedCollections, uploadedData);
          return;
        }
      }
      throw new Error("actionNotFound");
    } catch (error: unknown) {
      context.commit(workbenchStoreTypes.mutations.resetUploadedData);
      throw new Error((error as Error).message);
    } finally {
      context.commit(workbenchStoreTypes.mutations.resetUploadedData);
    }
  },
};
