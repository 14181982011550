import { CandidateInterface } from "../interfaces/Candidate.interface";
import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { Gender } from "@/features/modules/member/objects/Gender";
import { LinkedStatus } from "@/features/modules/status/objects/LinkedStatus";
import { LinkedUnit } from "@/features/modules/unit/objects/LinkedUnit";

import { AddressField, BooleanField, DateField, EnumField, ObjectField, StringArrayField, StringField } from "@/core/fields";

export class Candidate extends FirestoreDocument implements CandidateInterface {
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public gender: Gender = Gender.Male;
  public birthDate: Date | undefined = undefined;
  public address: AddressField = new AddressField();
  public phone: string | undefined = undefined;
  public email: string | undefined = undefined;
  public secondaryEmail: string | undefined = undefined;
  public status: LinkedStatus | undefined = undefined;
  public mainUnit: LinkedUnit | undefined = undefined;
  public skyguideEmployeeId: string | undefined = undefined;
  public skyguideEntryDate: Date | undefined = undefined;
  public solidarityConsent = false;
  public spokenLanguages: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Candidate {
    super.fromFirestore(data, id);

    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.gender = EnumField.fromFirestore<Gender>(data.gender, Object.values(Gender), Gender.Male);
    this.birthDate = DateField.fromFirestore(data.birthDate);
    this.address.fromFirestore(data.address);
    this.phone = StringField.fromFirestore(data.phone);
    this.email = StringField.fromFirestore(data.email);
    this.secondaryEmail = StringField.fromFirestore(data.secondaryEmail);
    this.status = ObjectField.fromFirestore<LinkedStatus>(data.status, (value) => new LinkedStatus(value));
    this.mainUnit = ObjectField.fromFirestore<LinkedUnit>(data.mainUnit, (value) => new LinkedUnit(value));
    this.skyguideEmployeeId = StringField.fromFirestore(data.skyguideEmployeeId);
    this.skyguideEntryDate = DateField.fromFirestore(data.skyguideEntryDate);
    this.solidarityConsent = BooleanField.fromFirestore(data.solidarityConsent);
    this.spokenLanguages = StringArrayField.fromFirestore(data.spokenLanguages);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.gender = EnumField.toFirestore(this.gender, Gender.Male);
    firestoreData.birthDate = DateField.toFirestore(this.birthDate);
    firestoreData.address = this.address.toFirestore();
    firestoreData.phone = StringField.toFirestore(this.phone);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.secondaryEmail = StringField.toFirestore(this.secondaryEmail);
    firestoreData.status = ObjectField.toFirestore(this.status, (value) => value.toFirestore());
    firestoreData.mainUnit = ObjectField.toFirestore(this.mainUnit, (value) => value.toFirestore());
    firestoreData.skyguideEmployeeId = StringField.toFirestore(this.skyguideEmployeeId);
    firestoreData.skyguideEntryDate = DateField.toFirestore(this.skyguideEntryDate);
    firestoreData.solidarityConsent = BooleanField.toFirestore(this.solidarityConsent);
    firestoreData.spokenLanguages = StringArrayField.toFirestore(this.spokenLanguages);

    return firestoreData;
  }

  public setFullName(): void {
    if (this.lastName != undefined && this.firstName != undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
    } else {
      this.fullName = undefined;
    }
  }
}
