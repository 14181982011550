import { LocaleModuleInterface } from "@/core/modules/locale/interfaces/LocaleModule.interface";

import { deFeaturesLabels } from "./de.ts";
import { enFeaturesLabels } from "./en.ts";
import { frFeaturesLabels } from "./fr.ts";

export const featuresLabels: LocaleModuleInterface = {
  name: "features",
  messages: { de: deFeaturesLabels, en: enFeaturesLabels, fr: frFeaturesLabels },
};
