import { ActionContext } from "vuex";

import { AuthStateInterface } from "@/core/modules/auth/interfaces/AuthState.interface";
import { authStoreTypes } from "../types";
import { koruAuth } from "@/core/modules/auth/module";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

export const changePassword = async (
  context: ActionContext<AuthStateInterface, KoruStateInterface>,
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  context.commit(authStoreTypes.mutations.loadingStart);

  try {
    return koruAuth.updatePassword(oldPassword, newPassword);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  } finally {
    context.commit(authStoreTypes.mutations.loadingStop);
  }
};
