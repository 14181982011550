import { koruBatch } from "@/core/modules/batch";
import { Section } from "@/features/modules/section/objects/Section";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const reorderSections = async (sections: Section[]): Promise<void> => {
  try {
    let order = 1;
    for (const section of sections) {
      koruBatch.update(getCollectionReference("sections").doc(section.id), { order: order });
      order++;
    }
    return koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
