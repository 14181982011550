import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { format } from "date-fns";

export class DateField {
  public static fromFirestore(value: unknown): Date | undefined {
    if (value === null || value === undefined) return undefined;
    return (value as firebase.firestore.Timestamp).toDate();
  }

  public static toFirestore(value: Date | undefined): firebase.firestore.Timestamp | null {
    if (value === undefined) return null;

    return firebase.firestore.Timestamp.fromDate(value);
  }

  public static toStringValue(value: Date | undefined, defaultValue: string, dateFormat = "yyyy-MM-dd"): string {
    if (value === undefined) return defaultValue;

    return format(value, dateFormat);
  }
}
