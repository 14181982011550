import { GetterTree } from "vuex";

import { ExchangeStateInterface } from "../interfaces/ExchangeState.interface";
import { exchangeStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";

export const getters: GetterTree<ExchangeStateInterface, KoruStateInterface> = {
  [exchangeStoreTypes.getters.getUploadedData]: (state: ExchangeStateInterface): string | undefined => {
    return state.uploadedData;
  },
};
