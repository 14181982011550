import { RouteRecordRaw } from "vue-router";

export const emergencyDocRoutes: Array<RouteRecordRaw> = [
  {
    path: "/emergency-docs",
    name: "EmergencyDocList",
    component: () => import("../views/EmergencyDocList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "emergencyDoc",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/emergency-docs/:emergencyDocId/edit",
    name: "EmergencyDocEdit",
    component: () => import("../views/EmergencyDocEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "emergencyDoc",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
];
