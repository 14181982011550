export const fr = {
  role: {
    allowNewUser: "Autoriser les nouveaux utilisateurs",
    allowNewUserError: "Sélectionnez une valeur",
    module: "Module",
    name: "Nom",
    nameError: "Veuillez entrer un nom valide",
    role: "Rôle",
    rights: "Autorisations",
    roles: "Rôles",
  },
  navigation: {
    role: "Rôles",
  },
  roleModules: {
    role: "Rôles",
  },
};
