import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { GroupInterface } from "../interfaces/Group.interface";

import { StringField } from "@/core/fields";

export class Group extends FirestoreDocument implements GroupInterface {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Group {
    super.fromFirestore(data, id);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
