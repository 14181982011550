export const en = {
  user: {
    activeTrue: "Enabled",
    activeFalse: "Disabled",
    allUsers: "All Users",
    email: "E-mail",
    emailAddress: "E-mail Address",
    emailAddressError: "Insert a valid and unique e-mail address",
    firstName: "Name",
    firstNameError: "Insert a valid first name",
    fullName: "Name",
    lastName: "Last Name",
    lastNameError: "Insert a valid last name",
    name: "Name",
    registration: "Registration",
    role: "Role",
    roleError: "Select a valid role",
    roleSelect: "Select a role",
    state: "Stato",
    user: "User",
    users: "Users",
  },
  navigation: {
    user: "Users",
  },
  roleModules: {
    user: "Users",
  },
};
