import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { NotificationInterface } from "../interfaces/Notification.interface";

import { StringArrayField, StringField } from "@/core/fields";

export class Notification extends FirestoreDocument implements NotificationInterface {
  public type: string | undefined = undefined;
  public reference: string | undefined = undefined;
  public message: string | undefined = undefined;
  public owners: string[] = [];
  public unread: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Notification {
    super.fromFirestore(data, id);

    this.type = StringField.fromFirestore(data.type);
    this.reference = StringField.fromFirestore(data.reference);
    this.message = StringField.fromFirestore(data.message);
    this.owners = StringArrayField.fromFirestore(data.owners);
    this.unread = StringArrayField.fromFirestore(data.unread);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.type = StringField.toFirestore(this.type);
    firestoreData.reference = StringField.toFirestore(this.reference);
    firestoreData.message = StringField.toFirestore(this.message);
    firestoreData.owners = StringArrayField.toFirestore(this.owners);
    firestoreData.unread = StringArrayField.toFirestore(this.unread);

    return firestoreData;
  }
}
