import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { LinkedSection } from "@/features/modules/section/objects/LinkedSection";
import { Section } from "@/features/modules/section/objects/Section";
import { sectionModel } from "@/features/modules/section/models/Section.model";
import { Unit } from "../objects/Unit";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const unitDummy: DummyModuleInterface = {
  collectionName: "units",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const sections: Section[] = await sectionModel.getDocuments();

    const units: Record<string, string[]> = {
      "ZRH ACC & OLLE": ["ZRH ACC", "ARFA/FIC/DELTA"],
      "GVA ACC & OLLW": ["GVA ACC", "FIC/DELTA"],
      "ZRH TWR/APP": ["ZRH TWR/APP"],
      "GVA TWR/APP": ["GVA TWR/APP"],
      "REGIO airfields": ["Bern", "Buochs", "Grenchen", "Lugano", "Sion", "St. Gallen"],
      "MIL units": ["ADDC", "Dübendorf", "Emmen", "Alpnach", "Locarno", "Meiringen", "Payerne"],
    };

    let orderIndex = 1;
    for (const sectionName of Object.keys(units)) {
      const section: Section | undefined = sections.find((section: Section) => section.name === sectionName);
      if (section === undefined) throw new Error(`Section ${sectionName} not found`);

      for (const unitName of units[sectionName]) {
        const unit = new Unit();
        unit.name = unitName;
        unit.section = LinkedSection.createFromSection(section);
        unit.order = orderIndex;

        unit.setTimestampFields("create");
        koruBatch.set(getCollectionReference("units").doc(), unit.toFirestore());
        orderIndex++;
      }
    }
  },
};
