import { EmptyStateInterface } from "@/core/modules/store/interfaces/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";

export const searchStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("searchState", undefined, undefined, actions);

export const useSearchStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return searchStore;
};
