import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { LinkedRole } from "@/core/modules/role/objects/LinkedRole";
import { UserDetail } from "./UserDetail";
import { UserInterface } from "../interfaces/User.interface";

import { getObjectValueWithField } from "@/core/modules/helpers";

import { BooleanField, DateField, ObjectField, ObjectStrictField, StringField } from "@/core/fields";

export class User extends FirestoreDocument implements UserInterface {
  public firstName: string | undefined = undefined;
  public lastName: string | undefined = undefined;
  public fullName: string | undefined = undefined;
  public fullNameReversed: string | undefined = undefined;
  public email: string | undefined = undefined;
  public role: LinkedRole | undefined = undefined;
  public detail: UserDetail = new UserDetail();
  public blocked = false;
  public lastSeen: Date | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): User {
    super.fromFirestore(data, id);

    this.firstName = StringField.fromFirestore(data.firstName);
    this.lastName = StringField.fromFirestore(data.lastName);
    this.fullName = StringField.fromFirestore(data.fullName);
    this.fullNameReversed = StringField.fromFirestore(data.fullNameReversed);
    this.email = StringField.fromFirestore(data.email);
    this.role = ObjectField.fromFirestore<LinkedRole>(data.role, (value) => new LinkedRole(value));
    this.detail = ObjectStrictField.fromFirestore<UserDetail>(data.detail, (value) => new UserDetail(value));
    this.blocked = BooleanField.fromFirestore(data.blocked);
    this.lastSeen = DateField.fromFirestore(data.lastSeen);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.firstName = StringField.toFirestore(this.firstName);
    firestoreData.lastName = StringField.toFirestore(this.lastName);
    firestoreData.fullName = StringField.toFirestore(this.fullName);
    firestoreData.fullNameReversed = StringField.toFirestore(this.fullNameReversed);
    firestoreData.email = StringField.toFirestore(this.email);
    firestoreData.role = ObjectField.toFirestore<LinkedRole>(this.role, (value) => value.toFirestore());
    firestoreData.detail = ObjectStrictField.toFirestore<UserDetail>(this.detail, (value) => value.toFirestore());
    firestoreData.blocked = BooleanField.toFirestore(this.blocked);
    firestoreData.lastSeen = DateField.toFirestore(this.lastSeen);

    if (this.id === "new") firestoreData.sendMail = true;

    return firestoreData;
  }

  public hasRightWithType(module: string, rights: Array<string>): boolean {
    if (rights.length <= 0) return true;

    for (const right of rights) {
      const allowedModules = getObjectValueWithField(this.role, right) as Array<string>;
      if (allowedModules.includes(module)) return true;
    }
    return false;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.lastName != undefined) this.searchKeys.push(this.lastName.toLowerCase());
    if (this.firstName != undefined) this.searchKeys.push(this.firstName.toLowerCase());
    if (this.email != undefined) this.searchKeys.push(this.email.toLowerCase());
  }

  public setFullNames(): void {
    if (this.lastName != undefined && this.firstName != undefined) {
      this.fullName = `${this.lastName} ${this.firstName}`;
      this.fullNameReversed = `${this.firstName} ${this.lastName}`;
    } else {
      this.fullName = undefined;
      this.fullNameReversed = undefined;
    }
  }
}
