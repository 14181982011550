import { EmptyStateInterface } from "@/core/modules/store/interfaces/EmptyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";

export const installerStore: KoruStoreModuleInterface<EmptyStateInterface> = createStoreModule("installerState", undefined, undefined, actions);

export const useInstallerStore = (): KoruStoreModuleInterface<EmptyStateInterface> => {
  return installerStore;
};
