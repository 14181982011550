export const deCoreLabels = {
  auth: {
    askNewPassword: "Nach neuem Passwort fragen",
    authenticationSubtitle: "Melden Sie sich mit Ihrem Konto an",
    authenticationTitle: "Authentifizierung",
    checkInbox: "Überprüfen Sie Ihr Postfach",
    choosePassword: "Wähle ein neues Passwort",
    email: "E-Mail-Adresse",
    emailBlank: "Geben Sie Ihre E-Mail-Adresse ein",
    emailError: "Die E-Mail-Adresse ist ungültig",
    forgetError: "Passwort konnte nicht wiederhergestellt werden",
    forgotPasswordTitle: "Passwort wiederherstellen",
    forgetPasswordStart: "Passwort vergessen?",
    forgotPasswordMiddle: "Require",
    forgetPasswordEnd: "Wiederherstellung jetzt!",
    inputEmail: "Geben Sie Ihre E-Mail-Adresse ein",
    login: "Anmeldung",
    loginFailed: "Authentifizierung fehlgeschlagen",
    password: "Passwort",
    passwordBlank: "Neues Passwort eingeben",
    passwordConfirm: "Neues Passwort bestätigen",
    passwordConfirmBlank: "Bitte geben Sie eine neue Passwortbestätigung ein",
    passwordConfirmError: "Die zwei Passwörter stimmen nicht überein",
    passwordError: "Bitte geben Sie ein gültiges Passwort ein",
    passwordErrorChars: "Passwort muss mindestens 8 Zeichen lang sein",
    passwordErrorLowercase: "Bitte geben Sie mindestens einen Kleinbuchstaben ein",
    passwordErrorNumber: "Bitte geben Sie mindestens eine Ziffer ein",
    passwordErrorUppercase: "Bitte geben Sie mindestens einen Großbuchstaben ein",
    passwordRequirementsTitle: "Anforderungen",
    passwordRequirementsChars: "Mindestens 8 Zeichen",
    passwordRequirementsLowercase: "Mindestens ein Kleinbuchstabe",
    passwordRequirementsNumber: "Mindestens eine Ziffer",
    passwordRequirementsUppercase: "Mindestens ein Großbuchstabe",
    passwordRememberedStart: "Passwort merken? Zurück zu",
    passwordRememberedEnd: "Authentifizierung",
    recoveryPasswordTitle: "Passwort wiederherstellen",
    resetPassword: "Passwort zurücksetzen",
    resetPasswordError: "Passwort konnte nicht zurückgesetzt werden",
    resetPasswordSuccessStart: "Neues Passwort wurde festgelegt. Gehe zu",
    resetPasswordSuccessEnd: "Authentifizierung",
  },
  buttons: {
    back: "Zurück",
    abbrechen: "Abbrechen",
    clear: "Klar",
    close: "Schließen",
    delete: "Löschen",
    download: "Herunterladen",
    disableUser: "Benutzer deaktivieren",
    edit: "Bearbeiten",
    enableUser: "Benutzer aktivieren",
    export: "Exportieren",
    import: "Importieren",
    load: "Laden",
    markAsRead: "Als gelesen markieren",
    noAction: "Keine Aktion verfügbar",
    print: "Drucken",
    run: "Lauf",
    save: "Speichern",
    search: "Suche",
    send: "Senden",
    sendNotification: "Benachrichtigung senden",
    toggleUser: "Zustand umkehren",
    upload: "Datei hochladen",
    view: "Ansicht",
  },
  dialog: {
    confirmCloseWithoutSave: "Schließen ohne Speichern?",
    confirmDelete: "Sind Sie sicher, dass Sie löschen möchten?",
    editingConfirm: "Nicht gespeicherte Daten gehen verloren, sind Sie sicher?",
    editingTitle: "Beenden ohne Speichern",
  },
  gen: {
    actions: "Aktionen",
    any: "Jeder",
    all: "Alle",
    backTo: "Zurück zu",
    browse: "Durchsuchen",
    confirmActionText: "Bestätigen Sie die Ausführung der ausgewählten Aktion?",
    confirmActionTitle: "Aktion bestätigen",
    dashboard: "Dashboard",
    deleteConfirm: "Sind Sie sicher, dass Sie löschen möchten",
    deleteObject: "das ausgewählte Objekt?",
    deleteTitle: "Löschen bestätigen",
    developedby: "entwickelt von",
    document: "Dokument",
    editPräfix: "Bearbeiten",
    empty: "Leer",
    errorContact: "Es ist ein Fehler aufgetreten, wenden Sie sich an den Entwickler",
    expirationDaysLeft: "verbleibende Tage",
    expirationExpired: "Abgelaufen",
    expirationNotAvailable: "Nicht erwartet",
    filter: "Filtern",
    findAddress: "Adresse suchen",
    generals: "Generäle",
    guest: "Gast",
    info: "Informationen",
    inputAddress: "Geben Sie eine Adresse ein",
    loading: "Wird geladen",
    logout: "Ausloggen",
    maintenanceDescription: "Entschuldigen Sie die Unannehmlichkeiten. Wartungsarbeiten sind im Gange, KORU wird bald wieder online sein.",
    maintenanceTitle: "Wir sind bald wieder da!",
    missingPrivileges: "Sie haben nicht die erforderlichen Rechte, um die Seite anzuzeigen",
    newPrefix: "Schaffen",
    no: "Nein",
    noData: "Keine Daten verfügbar",
    ok: "Ok",
    oops: "UPS!",
    pageNotFound: "Die angeforderte Seite ist nicht verfügbar",
    pageResults: "Ergebnisse von [[first]] bis [[last]] von [[totalRecords]] insgesamt",
    profile: "Profil",
    reorder: "Ordnen",
    search: "Suche",
    unknown: "unbekannt",
    user: "Benutzer",
    viewPrefix: "Aussicht",
    yes: "Ja",
  },
  navigation: {
    about: "Inforrmation",
    administration: "Verwaltung",
    content: "Inhalt",
    dashboard: "Planke",
    development: "Entwicklung",
    general: "Allgemeines",
    structure: "Struktur",
    tools: "Werkzeuge",
  },
  profile: {
    changeEmail: "E-Mail ändern",
    changePassword: "Passwort ändern",
    changePasswordButton: "Passwort ändern",
    email: "E-Mail-Adresse",
    oldPassword: "Altes Passwort",
    oldPasswordBlank: "Bitte geben Sie Ihr altes Passwort ein",
    newEmail: "Neue E-Mail-Adresse",
    newEmailError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    newPassword: "Neues Passwort",
    newPasswordBlank: "Bitte geben Sie Ihr neues Passwort ein",
  },
  rights: {
    readAll: "Alles lesen",
    readOwn: "Eigenes lesen",
    erstellen: "Erstellen",
    updateAll: "Alle ändern",
    updateOwn: "Eigene bearbeiten",
    deleteAll: "Alle löschen",
    deleteOwn: "Eigenes löschen",
  },
  roleModules: {},
  themes: {
    dim: "Nacht",
    light: "Licht",
    solarized: "Solarisiert",
  },
  toast: {
    error: {
      actionError: "Die Aktion konnte nicht abgeschlossen werden",
      backupDataNotSet: "Sicherungsdaten wurden nicht geladen",
      children: "Es gibt verwandte Artikel",
      delete: "Konnte nicht gelöscht werden",
      deleteFile: "Die Datei konnte nicht gelöscht werden",
      emailChanged: "E-Mail-Adresse konnte nicht geändert werden",
      feedbackSent: "Das Feedback konnte nicht gesendet werden",
      fiscalCode: "Die Berechnung des Fiskalcodes war nicht möglich",
      generic: "Ein unerwarteter Fehler ist aufgetreten",
      noAction: "Es wurde keine Aktion ausgewählt",
      notificationSent: "Die Benachrichtigung konnte nicht gesendet werden",
      notSupportedFileExtension: "Der hochgeladene Dateityp wird nicht unterstützt",
      passwordChanged: "Passwort konnte nicht geändert werden",
      save: "Speichern nicht möglich",
      search: "Konnte nicht suchen",
      sync: "Die Daten wurden von einem anderen Benutzer geändert",
      title: "Fehler!",
      toggleUser: "Fehler beim Ändern des Status",
      uploadFile: "Die Datei konnte nicht hochgeladen werden",
    },
    success: {
      actionSuccess: "Aktion erfolgreich ausgeführt",
      backupDownloadSuccess: "Sicherung erfolgreich erstellt",
      backupRestoreSuccess: "Sicherung erfolgreich wiederhergestellt",
      delete: "Löschen erfolgreich",
      emailChanged: "E-Mail-Adresse erfolgreich geändert",
      feedbackSent: "Feedback erfolgreich gesendet",
      generic: "Der Vorgang wurde erfolgreich abgeschlossen",
      notificationSent: "Benachrichtigung erfolgreich gesendet",
      passwordChanged: "Passwort erfolgreich geändert",
      save: "Speichern erfolgreich",
      title: "Fertig!",
      toggleUser: "Status erfolgreich geändert",
      uploadFile: "Die Datei wurde erfolgreich hochgeladen",
    },
    warning: {
      dummyNoCollections: "Mindestens eine Sammlung auswählen",
      filterMissing: "Alle Filter auswählen",
      formInvalid: "Überprüfe die eingegebenen Felder",
      searchNoResults: "Keine Ergebnisse gefunden",
      searchTextTooShort: "Geben Sie mindestens 3 Zeichen ein",
      title: "Achtung!",
    },
  },
};
