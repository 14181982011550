import { DummyStateInterface } from "../interfaces/DummyState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

export const dummyStore: KoruStoreModuleInterface<DummyStateInterface> = createStoreModule("dummyState", initialState, getters, actions, mutations);

export const useDummyStore = (): KoruStoreModuleInterface<DummyStateInterface> => {
  return dummyStore;
};
