import { ExchangeStateInterface } from "../interfaces/ExchangeState.interface";
import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";
import { mutations } from "./mutations";

export const exchangeStore: KoruStoreModuleInterface<ExchangeStateInterface> = createStoreModule(
  "exchangeState",
  initialState,
  getters,
  actions,
  mutations
);

export const useExchangeStore = (): KoruStoreModuleInterface<ExchangeStateInterface> => {
  return exchangeStore;
};
