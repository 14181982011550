import { MutationTree } from "vuex";

import { ExchangeStateInterface } from "../interfaces/ExchangeState.interface";
import { exchangeStoreTypes } from "./types";

export const mutations: MutationTree<ExchangeStateInterface> = {
  [exchangeStoreTypes.mutations.resetUploadedData]: (state: ExchangeStateInterface): void => {
    state.uploadedData = undefined;
  },
  [exchangeStoreTypes.mutations.setUploadedData]: (state: ExchangeStateInterface, data: string): void => {
    state.uploadedData = data;
  },
};
