import { GetterTree } from "vuex";

import { EmergencyDocStateInterface } from "../interfaces/EmergencyDocState.interface";
import { emergencyDocStoreTypes } from "./types";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { koruStoreTypes } from "@/core/modules/store/types";

export const getters: GetterTree<EmergencyDocStateInterface, KoruStateInterface> = {
  [emergencyDocStoreTypes.getters.isLoading]: (
    state: EmergencyDocStateInterface,
    getters: GetterTree<KoruStateInterface, KoruStateInterface>
  ): boolean => {
    return getters[koruStoreTypes.getters.isLoading] as unknown as boolean;
  },
  [emergencyDocStoreTypes.getters.isUploading]: (state: EmergencyDocStateInterface): boolean => {
    return state.isUploading;
  },
  [emergencyDocStoreTypes.getters.getUploadProgressValue]: (state: EmergencyDocStateInterface): number => {
    return state.uploadProgressValue;
  },
};
