export const fr = {
  section: {
    section: "Section",
    sections: "Sections",
    name: "Nom",
    nameError: "Entrez un nom valide",
  },
  navigation: {
    section: "Sections",
  },
  roleModules: {
    section: "Sections",
  },
};
