import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { MailMessageInterface } from "../interfaces/MailMessage.interface";

import { StringField } from "@/core/fields";

export class MailMessage extends FirestoreDocument implements MailMessageInterface {
  public recipientName: string | undefined = undefined;
  public recipientEmail: string | undefined = undefined;
  public subject: string | undefined = undefined;
  public message: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): MailMessage {
    super.fromFirestore(data, id);

    this.recipientName = StringField.fromFirestore(data.recipientName);
    this.recipientEmail = StringField.fromFirestore(data.recipientEmail);
    this.subject = StringField.fromFirestore(data.subject);
    this.message = StringField.fromFirestore(data.message);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.recipientName = StringField.toFirestore(this.recipientName);
    firestoreData.recipientEmail = StringField.toFirestore(this.recipientEmail);
    firestoreData.subject = StringField.toFirestore(this.subject);
    firestoreData.message = StringField.toFirestore(this.message);

    return firestoreData;
  }
}
