import { Notification } from "@/core/modules/notification/objects/Notification";
import { notificationModel } from "../Notification.model";

export const markNotificationAsRead = async (notificationId: string, userId: string): Promise<void> => {
  try {
    const notification: Notification = await notificationModel.getDocument(notificationId);

    const readIndex = notification.unread.indexOf(userId);
    if (readIndex < 0) {
      throw new Error(`User #${userId} not found in notification #${notificationId}`);
    }
    notification.unread.splice(readIndex, 1);

    await notificationModel.updateDocument(notification);
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
