import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Section } from "@/features/modules/section/objects/Section";
import { sectionModel } from "../Section.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const countSections = async (): Promise<number> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<Section> = await getCollectionReference(sectionModel.collectionName)
      .withConverter(sectionModel.firestoreConverter)
      .get();

    if (snapshot == undefined || snapshot.empty) return 0;

    return snapshot.docs.length;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
