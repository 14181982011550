export const de = {
  role: {
    allowNewUser: "Neue Benutzer zulassen",
    allowNewUserError: "Wert auswählen",
    module: "Modul",
    name: "Name",
    nameError: "Bitte geben Sie einen gültigen Namen ein",
    role: "Rolle",
    Rechte: "Berechtigungen",
    roles: "Rollen",
  },
  navigation: {
    role: "Rollen",
  },
  roleModules: {
    role: "Rollen",
  },
};
