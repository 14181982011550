export const it = {
  log: {
    action: "Azione",
    date: "Data",
    log: "Registro azioni",
    type: "Tipo",
    types: {
      error: "Errore",
      info: "Azione",
      warning: "Avviso",
    },
    user: "Utente",
  },
  navigation: {
    log: "Registro azioni",
  },
  roleModules: {
    log: "Registro azioni",
  },
};
