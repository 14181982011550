export const it = {
  workbench: {
    action: "Azione",
    actionSelect: "Seleziona un'azione",
    collections: "Collezioni",
    collectionsSelect: "Seleziona una o più collezioni",
    file: "File",
    fileSelect: "Seleziona un file per l'importazione",
    go: "Vai",
    modules: {
      deleteData: "Elimina una o più collezioni",
      exportData: "Esporta una o più collezioni",
      importData: "Importa collezioni da un backup",
      sendTestMail: "Invia una mail di prova",
      setSearchKeys: "Imposta le chiavi di ricerca",
    },
    types: {
      core: "Core",
      features: "Features",
    },
    workbench: "Banco di Lavoro",
  },
  navigation: {
    workbench: "Banco di Lavoro",
  },
  roleModules: {
    workbench: "Banco di Lavoro",
  },
};
