import { registerLocaleModule } from "@/core/modules/locale/helpers";

import { backupLocaleModule } from "@/core/modules/backup/locales";
import { changelogLocaleModule } from "@/core/modules/changelog/locales";
import { cloudEventLocaleModule } from "@/core/modules/cloud-event/locales";
import { dummyLocaleModule } from "@/core/modules/dummy/locales";
import { exchangeLocaleModule } from "@/core/modules/exchange/locales";
import { feedbackLocaleModule } from "@/core/modules/feedback/locales";
import { installerLocaleModule } from "@/core/modules/installer/locales";
import { logLocaleModule } from "@/core/modules/log/locales";
import { notificationLocaleModule } from "@/core/modules/notification/locales";
import { roleLocaleModule } from "@/core/modules/role/locales";
import { searchLocaleModule } from "@/core/modules/search/locales";
import { statLocaleModule } from "@/core/modules/stat/locales";
import { userLocaleModule } from "@/core/modules/user/locales";
import { utilityLocaleModule } from "@/core/modules/utility/locales";
import { whoLocaleModule } from "@/core/modules/who/locales";
import { workbenchLocaleModule } from "@/core/modules/workbench/locales";

export const registerCoreLocales = () => {
  registerLocaleModule(backupLocaleModule);
  registerLocaleModule(changelogLocaleModule);
  registerLocaleModule(cloudEventLocaleModule);
  registerLocaleModule(dummyLocaleModule);
  registerLocaleModule(exchangeLocaleModule);
  registerLocaleModule(feedbackLocaleModule);
  registerLocaleModule(installerLocaleModule);
  registerLocaleModule(logLocaleModule);
  registerLocaleModule(notificationLocaleModule);
  registerLocaleModule(roleLocaleModule);
  registerLocaleModule(searchLocaleModule);
  registerLocaleModule(statLocaleModule);
  registerLocaleModule(userLocaleModule);
  registerLocaleModule(utilityLocaleModule);
  registerLocaleModule(whoLocaleModule);
  registerLocaleModule(workbenchLocaleModule);
};
