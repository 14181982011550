import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Notification } from "@/core/modules/notification/objects/Notification";
import { notificationModel } from "../Notification.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const getUnreadNotifications = async (userId: string): Promise<Notification[]> => {
  try {
    const snapshot: firebase.firestore.QuerySnapshot<Notification> = await getCollectionReference(notificationModel.collectionName)
      .withConverter(notificationModel.firestoreConverter)
      .where("unread", "array-contains", userId)
      .orderBy("createdAt", "desc")
      .get();

    if (snapshot == undefined || snapshot.empty) return [];

    return snapshot.docs.map((doc) => doc.data());
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
