export const de = {
  unit: {
    unit: "Einheit",
    units: "Einheiten",
    name: "Name",
    nameError: "Geben Sie einen gültigen Namen ein",
    section: "Abschnitt",
    sectionError: "Wählen Sie einen Abschnitt aus",
  },
  navigation: {
    unit: "Einheiten",
  },
  roleModules: {
    unit: "Einheiten",
  },
};
