import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { firebaseFirestore } from "@/core/modules/firebase";

export function getCollectionReference(collection: string): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  return firebaseFirestore.collection(collection);
}

export function getCollectionGroupReference(collection: string): firebase.firestore.Query<firebase.firestore.DocumentData> {
  return firebaseFirestore.collectionGroup(collection);
}

export function getDocumentReference(path: string): firebase.firestore.DocumentReference {
  return firebaseFirestore.doc(path);
}
