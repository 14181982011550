import { FirestoreDocument } from "@/core/modules/firestore/objects/FirestoreDocument";
import { RoleInterface } from "../interfaces/Role.interface";

import { BooleanField, StringArrayField, StringField } from "@/core/fields";

export class Role extends FirestoreDocument implements RoleInterface {
  public name: string | undefined = undefined;
  public hasKoruAccess = true;
  public allowNewUser = true;
  public readAll: string[] = [];
  public readOwn: string[] = [];
  public create: string[] = [];
  public updateAll: string[] = [];
  public updateOwn: string[] = [];
  public deleteAll: string[] = [];
  public deleteOwn: string[] = [];

  public constructor(firestoreData?: Record<string, unknown>, id?: string) {
    super(id);
    if (firestoreData !== undefined) this.fromFirestore(firestoreData, id);
  }

  public fromFirestore(data: Record<string, unknown>, id?: string): Role {
    super.fromFirestore(data, id);

    this.name = StringField.fromFirestore(data.name);
    this.hasKoruAccess = BooleanField.fromFirestore(data.hasKoruAccess);
    this.allowNewUser = BooleanField.fromFirestore(data.allowNewUser);
    this.readAll = StringArrayField.fromFirestore(data.readAll);
    this.readOwn = StringArrayField.fromFirestore(data.readOwn);
    this.create = StringArrayField.fromFirestore(data.create);
    this.updateAll = StringArrayField.fromFirestore(data.updateAll);
    this.updateOwn = StringArrayField.fromFirestore(data.updateOwn);
    this.deleteAll = StringArrayField.fromFirestore(data.deleteAll);
    this.deleteOwn = StringArrayField.fromFirestore(data.deleteOwn);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);
    firestoreData.hasKoruAccess = BooleanField.toFirestore(this.hasKoruAccess);
    firestoreData.allowNewUser = BooleanField.toFirestore(this.allowNewUser);
    firestoreData.readAll = StringArrayField.toFirestore(this.readAll);
    firestoreData.readOwn = StringArrayField.toFirestore(this.readOwn);
    firestoreData.create = StringArrayField.toFirestore(this.create);
    firestoreData.updateAll = StringArrayField.toFirestore(this.updateAll);
    firestoreData.updateOwn = StringArrayField.toFirestore(this.updateOwn);
    firestoreData.deleteAll = StringArrayField.toFirestore(this.deleteAll);
    firestoreData.deleteOwn = StringArrayField.toFirestore(this.deleteOwn);

    return firestoreData;
  }

  public setSearchKeys(): void {
    this.searchKeys = [];
    if (this.name != undefined) this.searchKeys.push(this.name.toLowerCase());
  }
}
