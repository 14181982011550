export const en = {
  dummy: {
    collections: "Collections",
    collectionsSelect: "Select one or more collections",
    deleteBefore: "Delete existing data",
    dummy: "Dummy Data",
    go: "Go",
    subtitle: "Upload data to test the application",
  },
  navigation: {
    dummy: "Dummy Data",
  },
  roleModules: {
    dummy: "Dummy Data",
  },
  toast: {
    error: {
      uploadedDataNotFound: "Upload data to import",
    },
    success: {},
    warning: {},
  },
};
