export const fr = {
  cloudEvent: {
    cloudEvent: "Événement cloud",
    cloudEvents: "Événements cloud",
    createdAt: "Date et heure",
    details: "Détails",
    function: "Fonction",
    noDetails: "Aucun détail présent",
    type: "Type",
    types: {
      exception: "Exception",
      report: "Rapport",
    },
  },
  buttons: {
    showDetails: "Afficher les détails",
  },
  navigation: {
    cloudEvent: "Événements cloud",
  },
  roleModules: {
    cloudEvent: "Événements cloud",
  },
};
