import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { User } from "@/core/modules/user/objects/User";
import { userModel } from "../User.model";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const isUserEmailUnique = async (email: string, oldEmail: string | undefined): Promise<boolean> => {
  try {
    if (email == undefined) return false;
    if (oldEmail == undefined) oldEmail = "nil";
    const snapshot: firebase.firestore.QuerySnapshot<User> = await getCollectionReference(userModel.collectionName)
      .withConverter(userModel.firestoreConverter)
      .where("email", "==", email)
      .where("email", "!=", oldEmail)
      .limit(1)
      .get();

    return snapshot.size == 0;
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
