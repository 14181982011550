export const fr = {
  notification: {
    alert: "Vous avez {count}nouvelle notification | Vous avez {count}nouvelles notifications",
    messages: {
      backup: {
        failed: "La sauvegarde n'a pas pu être terminée",
        success: "Sauvegarde terminée avec succès",
      },
      cloudEvent: "Un nouvel événement cloud a été généré",
      releaseCreated: "La version {message} a été publiée",
      userCreated: "Un utilisateur a été créé pour {message}",
    },
    none: "Aucune notification à lire",
    notifications: "Notifications",
    types: {
      backup: "Sauvegardes",
      cloudEvent: "Événements cloud",
      releaseCreated: "Nouvelles versions",
      userCreated: "Nouveaux utilisateurs",
    },
  },
};
