import { ActionTree } from "vuex";

import { authStore } from "@/core/modules/auth/store";
import { authStoreTypes } from "@/core/modules/auth/store/types";
import { koruBatch } from "@/core/modules/batch";
import { KoruStateInterface } from "@/core/modules/store/interfaces/KoruState.interface";
import { ThemeInterface } from "../interfaces/Theme.interface";
import { ThemeStateInterface } from "../interfaces/ThemeState.interface";
import { themeStoreTypes } from "./types";
import { User } from "@/core/modules/user/objects/User";
import { UserDetail } from "@/core/modules/user/objects/UserDetail";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const actions: ActionTree<ThemeStateInterface, KoruStateInterface> = {
  async [themeStoreTypes.actions.setSelectedTheme](context, theme: ThemeInterface) {
    const user: User = authStore.getter(authStoreTypes.getters.getUser);

    try {
      const userDetail: UserDetail = user.detail;
      userDetail.theme = theme.name;

      koruBatch.update(getCollectionReference("users").doc(user.id), { detail: userDetail.toFirestore() });
      koruBatch.commit();
    } catch (error: unknown) {
      console.log((error as Error).message);
    }
  },
};
