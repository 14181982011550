import { registerRoutes } from "@/core/modules/router/helpers";

import { candidateRoutes } from "@/features/modules/candidate/router";
import { configurationRoutes } from "@/features/modules/configuration/router";
import { dashboardRoutes } from "@/features/modules/dashboard/router";
import { emergencyDocRoutes } from "@/features/modules/emergency-doc/router";
import { groupRoutes } from "@/features/modules/group/router";
import { languageRoutes } from "@/features/modules/language/router";
import { memberRoutes } from "@/features/modules/member/router";
import { sectionRoutes } from "@/features/modules/section/router";
import { statusRoutes } from "@/features/modules/status/router";
import { unitRoutes } from "@/features/modules/unit/router";

export const registerFeaturesRoutes = () => {
  registerRoutes(candidateRoutes);
  registerRoutes(configurationRoutes);
  registerRoutes(dashboardRoutes);
  registerRoutes(emergencyDocRoutes);
  registerRoutes(groupRoutes);
  registerRoutes(languageRoutes);
  registerRoutes(memberRoutes);
  registerRoutes(sectionRoutes);
  registerRoutes(statusRoutes);
  registerRoutes(unitRoutes);
};
