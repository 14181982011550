import { KoruStoreModuleInterface } from "@/core/modules/store/interfaces/KoruStoreModule.interface";
import { LocaleStateInterface } from "../interfaces/LocaleState.interface";

import { createStoreModule } from "@/core/modules/store/helpers";

import { actions } from "./actions";
import { getters } from "./getters";
import { initialState } from "./initial-state";

export const localeStore: KoruStoreModuleInterface<LocaleStateInterface> = createStoreModule("localeState", initialState, getters, actions);

export const useLocaleStore = (): KoruStoreModuleInterface<LocaleStateInterface> => {
  return localeStore;
};
