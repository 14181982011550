import { koruBatch } from "@/core/modules/batch";
import { Status } from "@/features/modules/status/objects/Status";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const reorderStatuses = async (statuses: Status[]): Promise<void> => {
  try {
    let order = 1;
    for (const status of statuses) {
      koruBatch.update(getCollectionReference("statuses").doc(status.id), { order: order });
      order++;
    }
    return koruBatch.commit();
  } catch (error: unknown) {
    throw new Error((error as Error).message);
  }
};
