export const en = {
  exchange: {
    addField: "Add field",
    chooseFile: "Choose file",
    column: "Column",
    export: "Export data",
    import: "Import data",
    profile: "Profile",
    profileError: "Select a profile",
    sourceFile: "Source file",
    sourceFileError: "Select a source file",
    type: "Type",
    types: {
      dateIt: "Date (it)",
      dateEn: "Date (en)",
      email: "E-mail",
      text: "Text",
    },
    value: "Value",
  },
  navigation: {
    exchange: "Data exchange",
  },
  roleModules: {
    exchange: "Data exchange",
  },
};
