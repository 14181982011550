import { FirestoreModel } from "@/core/modules/firestore/objects/FirestoreModel";
import { Section } from "../objects/Section";
import { SectionModelInterface } from "../interfaces/SectionModel.interface";
import { SectionWithUnits } from "../objects/SectionWithUnits";

import { countSections, getSectionsWithUnits, reorderSections } from "./methods";

export class SectionModel extends FirestoreModel<Section> implements SectionModelInterface {
  public constructor(collectionName: string, parentCollectionName?: string, syncCheck = true, beforeDeleteFunction?: string) {
    super(Section, collectionName, parentCollectionName, syncCheck, beforeDeleteFunction);
  }

  public async getDocuments(): Promise<Section[]> {
    return super.getDocuments("order", "asc");
  }

  public async getDocument(sectionId: string): Promise<Section> {
    return super.getDocument(sectionId);
  }

  public async createDocument(section: Section, logAction = true): Promise<string> {
    return super.createDocument(section, logAction);
  }

  public async updateDocument(section: Section, logAction = true): Promise<void> {
    return super.updateDocument(section, logAction);
  }

  public async deleteDocument(section: Section, logAction = true): Promise<void> {
    return super.deleteDocument(section, logAction);
  }

  public async searchDocuments(): Promise<Section[]> {
    return Promise.resolve([]);
  }

  /* custom methods */

  public async countSections(): Promise<number> {
    return countSections();
  }

  public async getSectionsWithUnits(): Promise<SectionWithUnits[]> {
    return getSectionsWithUnits();
  }

  public async reorderSections(sections: Section[]): Promise<void> {
    return reorderSections(sections);
  }
}

export const sectionModel: SectionModel = new SectionModel("sections", undefined, true, "featuresSectionBeforeDelete");
