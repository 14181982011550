export const it = {
  stat: {
    choose: "Scegli una statistica da visualizzare",
    stat: "Statistiche",
  },
  navigation: {
    stat: "Statistiche",
  },
  roleModules: {
    stat: "Statistiche",
  },
};
