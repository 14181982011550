export const it = {
  exchange: {
    addField: "Aggiungi campo",
    chooseFile: "Scegli file",
    column: "Colonna",
    export: "Esporta dati",
    import: "Importa dati",
    profile: "Profilo",
    profileError: "Seleziona un profilo",
    sourceFile: "File sorgente",
    sourceFileError: "Seleziona un file sorgente",
    type: "Tipo",
    types: {
      dateIt: "Data (it)",
      dateEn: "Data (en)",
      email: "E-mail",
      text: "Testo",
    },
    value: "Valore",
  },
  navigation: {
    exchange: "Scambio dati",
  },
  roleModules: {
    exchange: "Scambio dati",
  },
};
