import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { koruConfig } from "@/core/modules/config";
import { Role } from "../objects/Role";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const roleDummy: DummyModuleInterface = {
  collectionName: "roles",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const role: Role = new Role();

    const rights: string[] = koruConfig.authentication.roleModules;

    role.name = "Master";
    role.hasKoruAccess = true;
    role.allowNewUser = false;
    role.readAll = rights;
    role.readOwn = [];
    role.create = rights;
    role.updateAll = rights;
    role.updateOwn = [];
    role.deleteAll = rights;
    role.deleteOwn = [];

    const timestamp = new Date();
    role.createdAt = timestamp;
    role.createdBy = "install";
    role.updatedAt = timestamp;
    role.updatedBy = "install";

    koruBatch.set(getCollectionReference("roles").doc("95Clp88EfEyX5fY0DdNS"), role.toFirestore());
  },
};
