import { DummyModuleInterface } from "@/core/modules/dummy/interfaces/DummyModule.interface";
import { koruBatch } from "@/core/modules/batch";
import { Language } from "../objects/Language";

import { getCollectionReference } from "@/core/modules/firestore/helpers";

export const languageDummy: DummyModuleInterface = {
  collectionName: "languages",
  environments: ["development", "production"],
  action: async (): Promise<void> => {
    const languagesData: { name: string; shortName: string; flag: string }[] = [
      { name: "English", shortName: "en", flag: "gb" },
      { name: "Français", shortName: "fr", flag: "fr" },
      { name: "Deutsch", shortName: "de", flag: "de" },
      { name: "Italiano", shortName: "it", flag: "it" },
    ];

    for (let i = 0; i < languagesData.length; i++) {
      const languageData: { name: string; shortName: string; flag: string } = languagesData[i];

      const language = new Language();
      language.name = languageData["name"];
      language.shortName = languageData["shortName"];
      language.flag = languageData["flag"];
      language.order = i + 1;

      language.setTimestampFields("create");
      koruBatch.set(getCollectionReference("languages").doc(), language.toFirestore());
    }
  },
};
