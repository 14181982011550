import { registerRoutes } from "@/core/modules/router/helpers";

import { authRoutes } from "@/core/modules/auth/router";
import { backupRoutes } from "@/core/modules/backup/router";
import { changelogRoutes } from "@/core/modules/changelog/router";
import { cloudEventRoutes } from "@/core/modules/cloud-event/router";
import { dummyRoutes } from "@/core/modules/dummy/router";
import { exchangeRoutes } from "@/core/modules/exchange/router";
import { feedbackRoutes } from "@/core/modules/feedback/router";
import { installerRoutes } from "@/core/modules/installer/router";
import { logRoutes } from "@/core/modules/log/router";
import { notificationRoutes } from "@/core/modules/notification/router";
import { profileRoutes } from "@/core/modules/profile/router";
import { roleRoutes } from "@/core/modules/role/router";
import { searchRoutes } from "@/core/modules/search/router";
import { statRoutes } from "@/core/modules/stat/router";
import { userRoutes } from "@/core/modules/user/router";
import { utilityRoutes } from "@/core/modules/utility/router";
import { whoRoutes } from "@/core/modules/who/router";
import { workbenchRoutes } from "@/core/modules/workbench/router";

export const registerCoreRoutes = () => {
  registerRoutes(authRoutes);
  registerRoutes(backupRoutes);
  registerRoutes(changelogRoutes);
  registerRoutes(cloudEventRoutes);
  registerRoutes(dummyRoutes);
  registerRoutes(exchangeRoutes);
  registerRoutes(feedbackRoutes);
  registerRoutes(installerRoutes);
  registerRoutes(logRoutes);
  registerRoutes(notificationRoutes);
  registerRoutes(profileRoutes);
  registerRoutes(roleRoutes);
  registerRoutes(searchRoutes);
  registerRoutes(statRoutes);
  registerRoutes(userRoutes);
  registerRoutes(utilityRoutes);
  registerRoutes(whoRoutes);
  registerRoutes(workbenchRoutes);
};
