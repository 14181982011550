export const en = {
  candidate: {
    addresses: "Contact details",
    birthDate: "Birth date",
    birthDateError: "Select a valid birth date",
    candidate: "Candidate",
    candidates: "Candidates",
    country: "Country",
    createdAt: "Registered",
    email: "Primary e-mail address",
    emailError: "Enter a valid e-mail address",
    emailNote: "At least one e-mail address should be in the skyguide.ch domain",
    firstName: "First name",
    firstNameError: "Enter a valid first name",
    fullName: "Full name",
    gender: "Gender",
    genderError: "Select a gender",
    genders: {
      female: "Female",
      male: "Male",
    },
    info: "Information",
    lastName: "Last name",
    lastNameError: "Enter a valid last name",
    mainUnit: "Main unit",
    mainUnitError: "Select a main unit",
    miscData: "Additional data",
    phone: "Phone",
    phoneError: "Enter a valid phone number",
    province: "State",
    registeredNote: "Candidate registered on {timestamp}",
    secondaryEmail: "Secondary e-mail address",
    secondaryEmailError: "Enter a valid e-mail address",
    section: "Section",
    skyguideEmployeeId: "Skyguide employee ID",
    skyguideEmployeeIdError: "Enter a valid Skyguide employee ID",
    skyguideEntryDate: "Skyguide entry date",
    skyguideEntryDateError: "Enter a valid entry date",
    solidarityConsent: "Solidarity consent",
    spokenLanguages: "Spoken languages",
    status: "Status",
    statusError: "Select a status",
    street: "Address",
    town: "Town",
    workData: "Work data",
    zipCode: "ZIP code",
  },
  navigation: {
    candidate: "Candidates",
  },
  roleModules: {
    candidate: "Candidates",
  },
  buttons: {
    approveCandidate: "Approve candidate",
  },
  toast: {
    error: {
      approveCandidate: "Unable to approve candidate",
    },
    success: {
      approveCandidate: "Candidate approved",
    },
    warning: {},
  },
};
