
import { defineComponent, Ref, ref } from "vue";

import { koruStoreTypes } from "@/core/modules/store/types";
import { useKoruLocale } from "@/core/modules/locale";
import { useKoruStore } from "@/core/modules/store";

export default defineComponent({
  name: "SearchComponent",
  props: {
    searchActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["search-click", "search-hide"],
  setup(props, { emit }) {
    const { t } = useKoruLocale();
    const koruStore = useKoruStore();

    const searchInput: Ref<string> = ref("");

    const onEnter = () => {
      document.getElementById("search-input")?.focus();
    };

    const onSearchContainerClick = (event: Record<string, unknown>) => {
      emit("search-click", event);
    };

    const onInputKeydown = async (event: Record<string, unknown>) => {
      const key = event.which;

      if (key === 9 || key === 13) {
        // tab or enter
        await koruStore.dispatch(koruStoreTypes.actions.navigateTo, "/");
        await koruStore.dispatch(koruStoreTypes.actions.navigateTo, `/search/${searchInput.value}`);
        close();
      } else if (key === 27) {
        // escape
        close();
      }
    };

    const close = () => {
      emit("search-hide", event);
      searchInput.value = "";
    };

    return {
      onEnter,
      onInputKeydown,
      onSearchContainerClick,
      searchInput,
      t,
    };
  },
});
