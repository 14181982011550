export const de = {
  notification: {
    alert: "Sie haben {count} neue Benachrichtigungen | Sie haben {count} neue Benachrichtigungen",
    messages: {
      backup: {
        failed: "Die Sicherung konnte nicht abgeschlossen werden",
        success: "Sicherung erfolgreich abgeschlossen",
      },
      cloudEvent: "Ein neues Cloud-Ereignis wurde generiert",
      releaseCreated: "Version {message} wurde veröffentlicht",
      userCreated: "Ein Benutzer wurde für {Nachricht} erstellt",
    },
    none: "Keine Benachrichtigungen zum Lesen",
    notifications: "Benachrichtigungen",
    types: {
      backup: "Sicherungen",
      cloudEvent: "Cloud-Ereignisse",
      releaseCreated: "Neue Veröffentlichungen",
      userCreated: "Neue Benutzer",
    },
  },
};
