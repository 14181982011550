import { RouteRecordRaw } from "vue-router";

export const memberRoutes: Array<RouteRecordRaw> = [
  {
    path: "/members",
    name: "MemberList",
    component: () => import("../views/MemberList.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "member",
      requiredRights: ["readAll", "readOwn"],
    },
  },
  {
    path: "/members/:id/edit",
    name: "MemberEdit",
    component: () => import("../views/MemberEdit.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "member",
      requiredRights: ["create", "updateAll", "updateOwn"],
    },
  },
  {
    path: "/members/:id/:section?",
    name: "MemberView",
    component: () => import("../views/MemberView.vue"),
    meta: {
      requiresAuth: true,
      requiredModule: "member",
      requiredRights: ["readAll", "readOwn"],
    },
  },
];
