export const en = {
  role: {
    allowNewUser: "Allow new users",
    allowNewUserError: "Select a value",
    module: "Module",
    name: "Name",
    nameError: "Insert a valid name",
    role: "Role",
    rights: "Rights",
    roles: "Roles",
  },
  navigation: {
    role: "Roles",
  },
  roleModules: {
    role: "Roles",
  },
};
