import { Group } from "./Group";
import { LinkedFirestoreDocument } from "@/core/modules/firestore/objects/LinkedFirestoreDocument";
import { LinkedGroupInterface } from "../interfaces/LinkedGroup.interface";

import { StringField } from "@/core/fields";

export class LinkedGroup extends LinkedFirestoreDocument implements LinkedGroupInterface {
  public name: string | undefined = undefined;

  public constructor(firestoreData?: Record<string, unknown>) {
    super();
    if (firestoreData !== undefined) this.fromFirestore(firestoreData);
  }

  public static createFromGroup(group: Group): LinkedGroup {
    const linkedGroup: LinkedGroup = new LinkedGroup();
    linkedGroup.id = group.id;
    linkedGroup.name = group.name;

    return linkedGroup;
  }

  public fromFirestore(data: Record<string, unknown>): LinkedGroup {
    super.fromFirestore(data);

    this.name = StringField.fromFirestore(data.name);

    return this;
  }

  public toFirestore(): Record<string, unknown> {
    const firestoreData: Record<string, unknown> = super.toFirestore();

    firestoreData.name = StringField.toFirestore(this.name);

    return firestoreData;
  }
}
